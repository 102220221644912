export interface CSNYttrandenEpiContent {
    headerText: string
    descriptionText: string
    loadingErrorText: string
    noResultText: string
    adHocYttrandeText: string
    begaranYttrandeText: string
    skickadTillCsnStatusText: string
    underBedomningStatusText: string
    skickadText: string
    sparadText: string
    skapadText: string
    kundeInteHittaUtbildningsnamnText: string
    kundeInteHittaSkolnamnText: string
    adHocButtonText: string
    linkToAdHocYttrandeSkapa: string
    linkToAdHocYttrandeRedigera: string
}

export const defaultCSNYttrandenEpiContent: CSNYttrandenEpiContent = {
    headerText: 'epi:headerText',
    descriptionText: 'epi:descriptionText',
    loadingErrorText: 'epi:loadingErrorText',
    noResultText: 'epi:noResultText',
    adHocYttrandeText: 'epi:adHocYttrandeText',
    begaranYttrandeText: 'epi:begaranYttrandeText',
    skickadTillCsnStatusText: 'epi:skickadTillCsnStatusText',
    underBedomningStatusText: 'epi:underBedomningStatusText',
    skickadText: 'epi:skickadText',
    skapadText: 'epi:skapadText',
    sparadText: 'epi:sparadText',
    kundeInteHittaUtbildningsnamnText: 'epi:kundeInteHittaUtbildningsnamnText',
    kundeInteHittaSkolnamnText: 'epi:kundeInteHittaSkolnamnText',
    adHocButtonText: 'epi:adHocButtonText',
    linkToAdHocYttrandeSkapa: 'epi:linkToAdHocYttrandeSkapa',
    linkToAdHocYttrandeRedigera: 'epi:linkToAdHocYttrandeRedigera',
}
