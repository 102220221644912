export interface StudierTabCommonContent {
    csnStepTitle: string
    ansokanStepName: string
    ansokanStepTitle: string
    ansokanStepKey1: string
    ansokanStepKey2: string
    ansokanStepKey3: string
    radgivarensunderlagStepName: string
    radgivarensunderlagStepTitle: string
    radgivarensunderlagStepKey1: string
    beslutStepTitle: string
    beslutStepKey1: string
    studieintygStepTitle: string
    tillbakaButtonText: string
    stepperHeader: string
    errorLoadingText: string
    sparaUtkastToasterSuccessText: string
    sparaUtkastToasterFailureText: string
    markeradSomKlarToasterSuccessText: string
    markeradSomKlarToasterFailureText: string
    studiestodsansokanGodkandToasterSuccessText: string
    studiestodsansokanGodkandToasterFailureText: string
    beslutIsNeededToBeUnlockedFirstHeader: string
    beslutIsNeededToBeUnlockedFirstDescription: string
    beslutLinkText: string
}

export const defaultStudierTabCommonContent: StudierTabCommonContent = {
    tillbakaButtonText: 'epi:tillbakaButtonText',
    ansokanStepKey1: 'epi:ansokanStepKey1',
    ansokanStepKey2: 'epi:ansokanStepKey2',
    ansokanStepKey3: 'epi:ansokanStepKey3',
    ansokanStepName: 'epi:ansokanStepName',
    ansokanStepTitle: 'epi:ansokanStepTitle',
    beslutStepKey1: 'epi:beslutStepKey1',
    beslutStepTitle: 'epi:beslutStepTitle',
    stepperHeader: 'epi:stepperHeader',
    radgivarensunderlagStepKey1: 'epi:radgivarensunderlagStepKey1',
    radgivarensunderlagStepName: 'epi:radgivarensunderlagStepName',
    radgivarensunderlagStepTitle: 'epi:radgivarensunderlagStepTitle',
    studieintygStepTitle: 'epi:studieintygStepTitle',
    csnStepTitle: 'epi:csnStepTitle',
    errorLoadingText: 'epi:errorLoadingText',
    sparaUtkastToasterSuccessText: 'epi:sparaUtkastToasterSuccessText',
    sparaUtkastToasterFailureText: 'epi:sparaUtkastToasterFailureText',
    markeradSomKlarToasterSuccessText: 'epi:markeradSomKlarToasterSuccessText',
    markeradSomKlarToasterFailureText: 'epi:markeradSomKlarToasterFailureText',
    studiestodsansokanGodkandToasterSuccessText: 'epi:studiestodsansokanGodkandToasterSuccessText',
    studiestodsansokanGodkandToasterFailureText: 'epi:studiestodsansokanGodkandToasterFailureText',
    beslutIsNeededToBeUnlockedFirstHeader: 'epi:beslutIsNeededToBeUnlockedFirstHeader',
    beslutIsNeededToBeUnlockedFirstDescription: 'epi:beslutIsNeededToBeUnlockedFirstDescription',
    beslutLinkText: 'epi:beslutLinkText',
}
