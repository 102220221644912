import React from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CableRoundedIcon from '@mui/icons-material/CableRounded'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import TagRoundedIcon from '@mui/icons-material/TagRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import MoveUpRoundedIcon from '@mui/icons-material/MoveUpRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded'
import { EventName, EventColor } from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventTypes'

export const getEventIcon = (subject: EventName) => {
    switch (subject) {
        case 'StudiestodCreatedEvent':
        case 'CsnArendeCreatedEvent':
        case 'TrrSvarKompletterandeBidragToCsnCreatedEvent':
        case 'TrrSvarYttrandeToCsnCreatedEvent':
        case 'TrrYttrandeToCsnCreatedEvent':
            return <AutoStoriesRoundedIcon />
        case 'StudiestodStudieintygAddedEvent':
            return <AttachFileRoundedIcon />
        case 'StudiestodUnderlagAddedEvent':
        case 'CsnBegaranYttrandeAddedEvent':
        case 'CsnKompletterandeBidragAddedEvent':
        case 'StudiestodFinancialTransactionsAddedEvent':
        case 'StudiestodCsnArendeItemAddedEvent':
        case 'KompletterandeBidragSapAttemptAddedEvent':
        case 'TrrYttrandeToCsnAddedEvent':
        case 'StudiestodUpdatedEvent':
        case 'CsnBegaranYttrandeUpdatedEvent':
        case 'AdHocYttrandeUpdatedEvent':
        case 'SvarBegaranYttrandeSavedEvent':
        case 'SvarKompletterandeBidragSavedEvent':
        case 'AdHocYttrandeSavedEvent':
        case 'UpdateLastDateToSvaraEvent':
        case 'DomainObjectChangedEvent':
            return <EditRoundedIcon />
        case 'StudiestodMovedToNewArendeEvent':
            return <MoveUpRoundedIcon />
        case 'StudiestodHandlaggAnsokanAddedEvent':
            return <VisibilityOutlinedIcon />
        case 'StudiestodUnlockStateEvent':
        case 'StudiestodStateResetEvent':
            return <LockOpenRoundedIcon />
        case 'StudiestodLockedEvent':
            return <LockRoundedIcon />
        case 'StudiestodBeslutAddedEvent':
            return <CheckRoundedIcon />
        case 'CsnBeslutAddedEvent':
            return <PriorityHighRoundedIcon />
        case 'StudiestodNummerIsSetEvent':
            return <TagRoundedIcon />
        case 'CsnBegaranKoppladToAnsokanEvent':
            return <CableRoundedIcon />
        case 'AdHocYttrandeSentToCsnEvent':
        case 'SvarBegaranYttrandeSentToCsnEvent':
        case 'SvarKompletterandeBidragSentToCsnEvent':
        case 'TrrSvarYttrandeToCsnSentEvent':
        case 'TrrYttrandeToCsnSentEvent':
            return <ArrowForwardRoundedIcon />
        case 'AdHocYttrandeCreatedEvent':
            return <AssignmentRoundedIcon />
        case 'AdHocYttrandeDeletedEvent':
        case 'StudiestodDeletedEvent':
        case 'StudiestodCsnArendeItemRemovedEvent':
        case 'GdprDeleteUserProfileMetaEvent':
            return <DeleteForeverRoundedIcon />
        case 'StudiestodSetToEjAktuellEvent':
            return <ArchiveRoundedIcon />
        case 'StudiestodSetToAktuellEvent':
            return <UnarchiveRoundedIcon />
        default:
            return <QuestionMarkOutlinedIcon />
    }
}

export const getEventText = (subject: EventName) => {
    switch (subject) {
        case 'StudiestodCreatedEvent':
            return 'Studiestödsansökan inkom'
        case 'StudiestodNummerIsSetEvent':
            return 'Studiestödsansökan har tilldelats ett nummer'
        case 'StudiestodUpdatedEvent':
            return 'Kunden uppdaterat studiestödsansökan'
        case 'StudiestodStudieintygAddedEvent':
            return 'Kunden har laddat upp studieintyg'
        case 'StudiestodMovedToNewArendeEvent':
            return 'Studiestödsansökan flyttat till nytt ärende'
        case 'StudiestodHandlaggAnsokanAddedEvent':
            return 'Studiestödsansökan öppnat första gången i RÅD'
        case 'StudiestodUnlockStateEvent':
            return 'Studiestödsansökan steg upplåst i RÅD'
        case 'StudiestodLockedEvent':
            return 'Studiestödsansökan är låst för kunden'
        case 'StudiestodBeslutAddedEvent':
            return 'Beslut fattat på studiestödsansökan'
        case 'StudiestodUnderlagAddedEvent':
            return 'Underlag uppdaterat i RÅD'
        case 'UpdateLastDateToSvaraEvent':
            return 'Datumet för sista dagen att svara yttrandet uppdaterat'
        case 'CsnBegaranKoppladToAnsokanEvent':
            return 'Begärt yttrande kopplat till studiestödsansökan'
        case 'SvarBegaranYttrandeSavedEvent':
        case 'TrrSvarYttrandeToCsnCreatedEvent':
            return 'Svar på begärt yttrande uppdaterat'
        case 'CsnBegaranYttrandeUpdatedEvent':
            return 'Begärt yttrande uppdaterat'
        case 'SvarBegaranYttrandeSentToCsnEvent':
        case 'TrrSvarYttrandeToCsnSentEvent':
            return 'Svar på begärt yttrande skickad till CSN'
        case 'AdHocYttrandeSavedEvent':
        case 'TrrYttrandeToCsnAddedEvent':
        case 'TrrYttrandeToCsnCreatedEvent':
        case 'AdHocYttrandeUpdatedEvent':
            return 'Ad-hoc yttrande uppdaterat'
        case 'AdHocYttrandeDeletedEvent':
            return 'Ad-hoc yttrande borttaget'
        case 'AdHocYttrandeCreatedEvent':
            return 'Ad-hoc yttrande skapad'
        case 'AdHocYttrandeSentToCsnEvent':
        case 'TrrYttrandeToCsnSentEvent':
            return 'Ad-hoc yttrande skickat till CSN'
        case 'CsnBeslutAddedEvent':
            return 'Beslut inkom från CSN'
        case 'CsnArendeCreatedEvent':
            return 'CSN ärende skapat'
        case 'CsnBegaranYttrandeAddedEvent':
            return 'Begärt yttrande inkom från CSN'
        case 'CsnKompletterandeBidragAddedEvent':
            return 'Kompletterande bidrag inkom från CSN'
        case 'SvarKompletterandeBidragSavedEvent':
        case 'TrrSvarKompletterandeBidragToCsnCreatedEvent':
            return 'Svar på kompletterande bidrag uppdaterat'
        case 'SvarKompletterandeBidragSentToCsnEvent':
            return 'Kompletterande bidrag skickat till CSN'
        case 'KompletterandeBidragSapAttemptAddedEvent':
            return 'Kompletterande bidrag, försökt skicka till SAP'
        case 'DomainObjectChangedEvent':
            return 'Domain objektet uppdaterat'
        case 'StudiestodDeletedEvent':
            return 'Studiestödsansökan borttaget'
        case 'StudiestodStateResetEvent':
            return 'Studiestödsansökan har fått sitt state återställt'
        case 'StudiestodSetToAktuellEvent':
            return 'Studiestödsansökan är satt som aktuell igen'
        case 'StudiestodSetToEjAktuellEvent':
            return 'Studiestödsansökan är satt som ej aktuell'
        case 'StudiestodFinancialTransactionsAddedEvent':
            return 'Financial transactions uppdaterat'
        case 'StudiestodCsnArendeItemAddedEvent':
            return 'CSN ärende item uppdaterat'
        case 'StudiestodCsnArendeItemRemovedEvent':
            return 'CSN ärende item borttaget'
        case 'GdprDeleteUserProfileMetaEvent':
            return 'GDPR delete har tagit bort kunden'
        default:
            return subject
    }
}

export const getEventColor = (subject: EventName): EventColor => {
    switch (subject) {
        case 'StudiestodHandlaggAnsokanAddedEvent':
            return 'info'
        case 'StudiestodBeslutAddedEvent':
        case 'CsnBeslutAddedEvent':
            return 'success'
        case 'StudiestodCreatedEvent':
        case 'StudiestodUpdatedEvent':
        case 'StudiestodStudieintygAddedEvent':
            return 'primary'
        case 'StudiestodLockedEvent':
        case 'StudiestodSetToEjAktuellEvent':
        case 'StudiestodUnlockStateEvent':
            return 'warning'
        case 'StudiestodSetToAktuellEvent':
            return 'success'
        default:
            return undefined
    }
}
