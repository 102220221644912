import React, { useEffect } from 'react'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { isNil, isNotNil } from 'ramda'
import StudiestodStatusChip from '@local/src/components/StudiestodStatusChip/StudiestodStatusChip'
import { Box, IconButton, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { useNavigateToPage } from '@local/src/hooks'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

import { StudiestodStepType } from '../../Models/StudiestodAnsokan.types'
import { useGetCurrentArende, useGetCurrentStudiestodansokan } from '../../Hooks'
import LabelValue from '../LabelValue/LabelValue'

import useStudiestodSteps from './useStudiestodSteps'

interface Props {
    changeStep(stepName: StudiestodStepType): void
    activeStep: StudiestodStepType
}

const StudiestodStepper = ({ changeStep, activeStep }: Props) => {
    const { tCommon } = useCommonTranslation()

    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const { arende } = useGetCurrentArende()

    const { navigateToStudierTab } = useNavigateToPage()
    const goBack = () => navigateToStudierTab()

    useEffect(() => {
        if (isNil(arende?.id) || isNil(studiestodAnsokan?.id)) return

        if (arende.id !== studiestodAnsokan.arendeId) {
            goBack()
        }
    }, [arende?.id, studiestodAnsokan?.arendeId])

    const { steps, activeStepAsNumber } = useStudiestodSteps(activeStep, studiestodAnsokan)

    return (
        <Stack spacing={2}>
            <Box>
                <IconButton onClick={goBack} disableRipple>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ArrowBackIosNewRoundedIcon fontSize="small" />
                        <Typography>{tCommon('tillbakaButtonText')}</Typography>
                    </Stack>
                </IconButton>
            </Box>

            <Stack>
                {studiestodAnsokan?.studiestodNummer && (
                    <Typography variant="subtitle1" color={(theme) => theme.palette.text.secondary}>
                        {`#${studiestodAnsokan?.studiestodNummer}`}
                    </Typography>
                )}

                <Typography sx={{ wordBreak: 'break-word' }} variant="h6">
                    {studiestodAnsokan?.ansokanNamn}
                </Typography>
            </Stack>

            <Box>
                <StudiestodStatusChip status={studiestodAnsokan?.studiestodAnsokanState} />
            </Box>

            <Stepper data-testid="studiestod-stepper" activeStep={activeStepAsNumber} orientation="vertical" nonLinear>
                {steps.map((step, index) => (
                    <Step key={step.label} onClick={() => changeStep(step.stepEnum)} sx={{ cursor: 'pointer' }} expanded>
                        <StepLabel>{step.label}</StepLabel>
                        <StepContent>
                            {step.content
                                .filter((x) => isNotNil(x.value))
                                .map((s) => (
                                    <Stack key={`${activeStep}-${s.key}-${index}`} direction="row" spacing={1}>
                                        <CheckOutlinedIcon color="primary" />
                                        <Stack>
                                            <LabelValue label={s.key} value={s.value} />
                                        </Stack>
                                    </Stack>
                                ))}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    )
}

export default StudiestodStepper
