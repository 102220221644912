export interface KoptUtbildningEpiContent {
    koptUtbildningHeader: string
    koptUtbildningDescription: string
    koptUtbildningValutaomvandlareDescription: string
    koptUtbildningInputLabel: string
}

export const defaultKoptUtbildningEpiContent: KoptUtbildningEpiContent = {
    koptUtbildningHeader: 'epi:koptUtbildningHeader',
    koptUtbildningDescription: 'epi:koptUtbildningDescription',
    koptUtbildningValutaomvandlareDescription: 'epi:koptUtbildningValutaomvandlareDescription',
    koptUtbildningInputLabel: 'epi:koptUtbildningInputLabel',
}
