export interface KompletterandeStudiestodEpiContent {
  kompletterandeStudiestodBeraknadStudiestartText: string
  kompletterandeStudiestodPreliminarErsattningText: string
  kompletterandeStudiestodUtbildningslangdText: string
  kompletterandeStudiestodUtbildningslangdTooltip: string
  kompletterandeStudiestodStudietaktLabel: string
  kompletterandeStudiestodUtbildningslangdLabel: string
  kompletterandeStudiestodHeader: string
  kompletterandeStudiestodLeadText: string
  kompletterandeStudiestodErsattningLinkText: string
  kompletterandeStudiestodErsattningLink: string
}

export interface KompletterandeStudiestodFelMeddelandeEpiContent {
  kompletterandeStudiestodPreliminarErsattningMin: string
  kompletterandeStudiestodPreliminarErsattningMax: string
  kompletterandeStudiestodPreliminarErsattningRequired: string
  kompletterandeStudiestodUtbildningslangdRequired: string
  kompletterandeStudiestodUtbildningslangdMin: string
  kompletterandeStudiestodUtbildningslangdMax: string
}

export const defaultKompletterandeStudiestodFelMeddelandeEpiContent: KompletterandeStudiestodFelMeddelandeEpiContent = {
  kompletterandeStudiestodPreliminarErsattningMin: 'epi:kompletterandeStudiestodPreliminarErsattningMin',
  kompletterandeStudiestodPreliminarErsattningMax: 'epi:kompletterandeStudiestodPreliminarErsattningMax',
  kompletterandeStudiestodPreliminarErsattningRequired: 'epi:kompletterandeStudiestodPreliminarErsattningRequired',
  kompletterandeStudiestodUtbildningslangdRequired: 'epi:kompletterandeStudiestodUtbildningslangdRequired',
  kompletterandeStudiestodUtbildningslangdMin: 'epi:kompletterandeStudiestodUtbildningslangdMin',
  kompletterandeStudiestodUtbildningslangdMax: 'epi:kompletterandeStudiestodUtbildningslangdMax',
}

export const defaultKompletterandeStudiestodEpiContent: KompletterandeStudiestodEpiContent = {
  kompletterandeStudiestodHeader: 'epi:kompletterandeStudiestodHeader',
  kompletterandeStudiestodLeadText: 'epi:kompletterandeStudiestodLeadText',
  kompletterandeStudiestodErsattningLinkText: 'epi:kompletterandeStudiestodErsattningLinkText',
  kompletterandeStudiestodErsattningLink: 'epi:kompletterandeStudiestodErsattningLink',
  kompletterandeStudiestodUtbildningslangdTooltip: 'epi:kompletterandeStudiestodUtbildningslangdTooltip',
  kompletterandeStudiestodUtbildningslangdLabel: 'epi:kompletterandeStudiestodUtbildningslangdLabel',
  kompletterandeStudiestodStudietaktLabel: 'epi:kompletterandeStudiestodStudietaktLabel',
  kompletterandeStudiestodUtbildningslangdText: 'epi:kompletterandeStudiestodUtbildningslangdText',
  kompletterandeStudiestodPreliminarErsattningText: 'epi:kompletterandeStudiestodPreliminarErsattningText',
  kompletterandeStudiestodBeraknadStudiestartText: 'epi:kompletterandeStudiestodBeraknadStudiestartText',
}
