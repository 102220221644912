import React, { Fragment, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CsnAdHocYttrandeApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { formatToDate } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { scrollElementIntoView } from '@local/src/hooks/useScrollToError'
import { Chip, Stack, Typography, Card, CardActionArea, CardContent, List, ListItem, ListItemText } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { removeTrailingSlash } from '@local/src/utils/helpers'
import { isNil } from 'ramda'
import { useNavigateToPage, usePickEpiContentWithFallback } from '@local/src/hooks'
import { useTranslation } from 'react-i18next'

import { getUtbildningsanordnareOrSkolnamn, getUtbildningsnamn } from './Helpers/UtbildningsHelper'

interface Props {
    adHocYttrande: CsnAdHocYttrandeApiModel
}
const AdHocYttrandeListItemCard = ({ adHocYttrande }: Props) => {
    const { createdBy, sentToCsnBy, lastUpdatedBy, yttrande } = adHocYttrande

    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const {
        studieAnsokan: { csnYttranden },
    } = usePickEpiContentWithFallback('studiertab')

    const { navigateOutsideMf } = useNavigateToPage()
    const { state } = useLocation<{ adhocYttrandeId: string } | undefined>()

    const editAdHocUrl = `${removeTrailingSlash(csnYttranden.linkToAdHocYttrandeRedigera)}/${yttrande.id}`
    const goToRedigeraAdHocYttrande = () => navigateOutsideMf(editAdHocUrl)

    useEffect(() => {
        if (state?.adhocYttrandeId) {
            const adHocYttrandeListItem = document.getElementById(state.adhocYttrandeId)
            scrollElementIntoView(adHocYttrandeListItem, -100)
        }
    }, [state?.adhocYttrandeId])

    const hasBeenUpdated = !isNil(lastUpdatedBy)
    const hasBeenSentToCsn = !isNil(sentToCsnBy)

    return (
        <Card sx={{ p: 0 }} data-testid="adhoc-yttrande-list-item" id={adHocYttrande?.yttrande?.id}>
            <CardActionArea onClick={goToRedigeraAdHocYttrande}>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h6">{t('adHocYttrandeText')}</Typography>
                        <Chip
                            data-testid={`adhoc-yttrande-list-item-chip-${hasBeenSentToCsn ? 'sent' : 'not-sent'}`}
                            variant={hasBeenSentToCsn ? 'filled' : 'outlined'}
                            color="primary"
                            label={hasBeenSentToCsn ? t('skickadTillCsnStatusText') : t('underBedomningStatusText')}
                        />
                    </Stack>
                    <Stack spacing={2}>
                        <Stack direction="row" spacing={0.5}>
                            <Typography variant="subtitle2">
                                {t('skapadText')}: {formatToDate(createdBy?.lastUpdatedAt)}
                            </Typography>
                            {hasBeenUpdated && (
                                <Fragment>
                                    <Typography variant="subtitle2">|</Typography>
                                    <Typography variant="subtitle2">{`${t('sparadText')}: ${formatToDate(
                                        lastUpdatedBy.lastUpdatedAt
                                    )}`}</Typography>
                                </Fragment>
                            )}
                            {hasBeenSentToCsn && (
                                <Fragment>
                                    <Typography variant="subtitle2">|</Typography>
                                    <Typography variant="subtitle2">
                                        {`${t('skickadText')}: ${formatToDate(sentToCsnBy.lastUpdatedAt)}`}
                                    </Typography>
                                </Fragment>
                            )}
                        </Stack>
                        <List>
                            {yttrande.utbildningar?.map((utbildning, index) => (
                                <ListItem
                                    key={`YttrandeUtbildningListItem-${uuidv4()}`}
                                    disablePadding
                                    divider={index !== yttrande.utbildningar?.length - 1}
                                >
                                    <ListItemText
                                        title="Skola/Anordnare"
                                        primary={getUtbildningsnamn(utbildning, t('kundeInteHittaUtbildningsnamnText'))}
                                        secondary={getUtbildningsanordnareOrSkolnamn(utbildning, t('kundeInteHittaSkolnamnText'))}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default AdHocYttrandeListItemCard
