import { pickAll, isEmpty, mergeDeepLeft } from 'ramda'
import { useMemo } from 'react'
import { useEpiContent } from '@trr/app-shell-data'

import { epiFallbackContent } from '../utils/helpers/ContentHelper'
import { mapEpiContent } from '../utils/helpers/EpiContentHelper'
import { IStudiestodEpiContent } from '../@types/StudiestodEpiContent'

type EpiContentPaths = keyof IStudiestodEpiContent

const useEpiContentWithFallback = (): IStudiestodEpiContent => {
    const content = useEpiContent()
    const mappedContent = useMemo(() => mapEpiContent<IStudiestodEpiContent>(content), [content])

    return {
        studiertab: mergeDeepLeft(mappedContent.studiertab, epiFallbackContent.studiertab),
    }
}

export const usePickEpiContentWithFallback = <T extends EpiContentPaths, K extends keyof IStudiestodEpiContent[T]>(
    paths: T,
    ...keys: K[]
): Pick<IStudiestodEpiContent[T], K> => {
    const content = useEpiContent()
    const mappedContent = useMemo(() => mapEpiContent<IStudiestodEpiContent>(content), [content])

    const epiContent = {
        studiertab: mergeDeepLeft(mappedContent.studiertab, epiFallbackContent.studiertab),
    }

    const res = epiContent[paths]

    return isEmpty(keys) ? pickAll(Object.keys(res), res) : pickAll(keys as readonly string[], res)
}

export default useEpiContentWithFallback
