export interface StudierExcelRaknesnurror {
  studierExcelRaknesnurraStudieersattningLink: string
  studierExcelRaknesnurraStudieersattningLinkText: string
  studierExcelRaknesnurraStudielonLink: string
  studierExcelRaknesnurraStudielonLinkText: string
}

export const defaultStudierExcelRaknesnurrorContent: StudierExcelRaknesnurror = {
  studierExcelRaknesnurraStudieersattningLink: 'epi:studierExcelRaknesnurraStudieersattningLink',
  studierExcelRaknesnurraStudieersattningLinkText: 'epi:studierExcelRaknesnurraStudieersattningLinkText',
  studierExcelRaknesnurraStudielonLink: 'epi:studierExcelRaknesnurraStudielonLink',
  studierExcelRaknesnurraStudielonLinkText: 'epi:studierExcelRaknesnurraStudielonLinkText',
}
