import React, { Fragment, useState } from 'react'
import { PageWrapper } from '@local/src/components/PageWrapper'
import { FormProvider } from 'react-hook-form'
// import { DevTool } from '@hookform/devtools'
import { Divider, Grid, Stack } from '@mui/material'
import { useSignalRStudiestodDetailEvent } from '@local/src/hooks/SignalR'
import SetEjAktuellAction from '@local/src/features/StudiestodAnsokan/Components/SetEjAktuellAction/SetEjAktuellAction'
import EjAktuellAlert from '@local/src/features/StudiestodAnsokan/Components/EjAktuellAlert/EjAktuellAlert'
import IsDeletedDialog from '@local/src/features/StudiestodAnsokan/Components/IsDeletedDialog/IsDeletedDialog'
import { useSignalR } from '@trr/app-shell-data'
import DomainEventsLog from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/DomainEventsLog'
import useCurrentUser from '@local/src/hooks/useCurrentUser'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

import { StudiestodStepper, StudiestodActiveStepComponent } from './Components/StudiestodStepper/'
import useUpdateUnderlagForm from './Hooks/useUpdateUnderlagForm'
import { useFormStepUrl, useGetCurrentArende, useGetCurrentStudiestodansokan } from './Hooks'

const StudiestodAnsokanDetail = () => {
    const { tCommon } = useCommonTranslation()
    const { changeStep, activeStep } = useFormStepUrl()
    const signalR = useSignalR()
    const medarbetare = signalR?.medarbetare
    const { userHasRole } = useCurrentUser()
    const userHasAdminRole = userHasRole(['RADGIVNINGS-ADMINISTRATOR'])
    const { studiestodAnsokan, isLoading, isError, error, isEjAktuell } = useGetCurrentStudiestodansokan()

    const [isDeleted, setIsDeleted] = useState(false)
    const { arende } = useGetCurrentArende()

    const formMethods = useUpdateUnderlagForm(studiestodAnsokan, arende?.arendeTyp)

    const ansokanIsDeleted = isDeleted || (isError && error?.status === 410)

    useSignalRStudiestodDetailEvent(medarbetare, studiestodAnsokan?.id, () => setIsDeleted(true))

    return (
        <FormProvider {...formMethods}>
            <PageWrapper isLoading={isLoading} errorLoadingData={isError} errorText={tCommon('error_generic')}>
                <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Stack spacing={2}>
                            <StudiestodStepper changeStep={changeStep} activeStep={activeStep} />
                            {studiestodAnsokan?.canBeSetToEjAktuell && (
                                <Fragment>
                                    <Divider />
                                    <SetEjAktuellAction
                                        isEjAktuell={isEjAktuell}
                                        hasFakturor={studiestodAnsokan.hasFakturor}
                                        hasErsattning={studiestodAnsokan.hasStudieersattning}
                                    />
                                </Fragment>
                            )}
                            {userHasAdminRole && <DomainEventsLog studiestod={studiestodAnsokan} />}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Stack spacing={2}>
                            <StudiestodActiveStepComponent activeStep={activeStep} />
                            {isEjAktuell && <EjAktuellAlert orsak={studiestodAnsokan?.ejAktuell?.orsakMedarbetare} />}
                        </Stack>
                    </Grid>
                </Grid>
            </PageWrapper>
            <IsDeletedDialog
                isDeleted={ansokanIsDeleted}
                studiestodNummer={studiestodAnsokan?.studiestodNummer}
                ansokanNamn={studiestodAnsokan?.ansokanNamn}
            />
            {/* <DevTool control={formMethods.control} placement="bottom-right" /> */}
        </FormProvider>
    )
}

export default StudiestodAnsokanDetail
