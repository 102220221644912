import { StudiestodApiResponse } from '@local/src/features/StudiestodAnsokan/Models/StudiestodansokanApiResponse'
import {
    Box,
    SpeedDial,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    SpeedDialIcon,
    Switch,
} from '@mui/material'
import React, { useState } from 'react'
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { formatToDateTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import useGetDomainEventsByAnsokanId from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Hooks/useGetDomainEventsByAnsokanId'
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded'
import {
    getEventColor,
    getEventIcon,
    getEventText,
} from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventHelper'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import { useSignalRJoinGroup, useSignalROnTargetMethod } from '@local/src/hooks/SignalR'
import { useQueryClient } from '@tanstack/react-query'
import { EventName } from '@local/src/features/StudiestodAnsokan/Components/DomainEventsLog/Utils/domainEventTypes'
import Loader from '@local/src/components/Loader'
import { useSignalR } from '@trr/app-shell-data'

interface Props {
    studiestod: StudiestodApiResponse
}

const DomainEventsLog = ({ studiestod }: Props) => {
    const [open, setOpen] = useState(false)
    const [hasNewEvent, setHasNewEvent] = useState(false)
    const [showCsnEvent, setShowCsnEvent] = useState(false)

    const handleOpen = () => {
        setOpen(true)
        setHasNewEvent(false)
    }
    const handleClose = () => setOpen(false)

    const studiestodId = studiestod?.id
    const signalR = useSignalR()
    const medarbetare = signalR?.medarbetare
    const { domainEvents, isFetching } = useGetDomainEventsByAnsokanId(studiestod?.arendeId, studiestod?.id, open)
    const queryClient = useQueryClient()

    useSignalRJoinGroup({
        hub: medarbetare,
        groupName: 'StudiestodEvent',
        groupIdentifier: studiestodId,
    })

    useSignalROnTargetMethod({
        hub: medarbetare,
        targetMethod: 'NewDomainEvent',
        callBack: () => {
            setHasNewEvent(true)
            void queryClient.invalidateQueries(['domainEvents', studiestodId])
        },
    })

    const hasCsnEvents = domainEvents?.filter((f) => f.source === '/csn')?.length > 0

    return (
        <Box sx={{ transform: 'translateZ(0px)', width: 1 }}>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', left: 0, top: -16 }}
                icon={<SpeedDialIcon icon={hasNewEvent ? <NotificationImportantRoundedIcon /> : <HistoryRoundedIcon />} />}
                FabProps={
                    hasNewEvent
                        ? {
                              sx: {
                                  bgcolor: 'green',
                                  '&:hover': {
                                      bgcolor: 'green',
                                  },
                              },
                          }
                        : undefined
                }
                onClose={(_, reason) => reason === 'toggle' && handleClose()}
                onOpen={(_, reason) => reason === 'toggle' && handleOpen()}
                open={open}
            />
            <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
                <DialogTitle>Händelser för {`#${studiestod?.studiestodNummer} - ${studiestod?.ansokanNamn}`}</DialogTitle>
                <DialogContent>
                    {hasCsnEvents && (
                        <DialogContentText>
                            Visa även CSN-händelser?
                            <Switch checked={showCsnEvent} onChange={(e, c) => setShowCsnEvent(c)} />
                        </DialogContentText>
                    )}
                    <Timeline position="alternate">
                        {isFetching && <Loader />}
                        {!isFetching &&
                            domainEvents
                                .filter((d) => (showCsnEvent ? true : d.source !== '/csn'))
                                .map((de) => (
                                    <TimelineItem key={de.id}>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot color={getEventColor(de.subject as EventName)}>
                                                {getEventIcon(de.subject as EventName)}
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant="h6" component="span">
                                                {getEventText(de.subject as EventName)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {formatToDateTime(de.time)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Gjord av: {de.name}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                    </Timeline>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default DomainEventsLog
