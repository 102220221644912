export interface CSNBeslutEpiContent {
  beslutHeaderText: string
  beslutBodyText: string
  beslutInkommet: string
  beslutTypAvBeslut: string
  beslutsutfall: string
  utbildningar: string
  studieperiod: string
  beslutIngaBeslut: string
  beslutIngaUtbildningar: string
  beslutIngaStudieperioder: string
  omfattning: string
  beslutGodkandStudieforsakran: string
  beslutNoMoreInfo: string
  csnExternalLink: string
  csnExternalLinkText: string
}

export const defaultCSNBeslutEpiContent: CSNBeslutEpiContent = {
  beslutHeaderText: 'epi:beslutHeaderText',
  beslutBodyText: 'epi:beslutBodyText',
  beslutInkommet: 'epi:beslutInkommet',
  beslutTypAvBeslut: 'epi:beslutTyp',
  beslutsutfall: 'epi:beslutsutfall',
  utbildningar: 'epi:utbildningar',
  studieperiod: 'epi:studieperiod',
  beslutIngaBeslut: 'epi:beslutIngaBeslut',
  beslutIngaUtbildningar: 'epi:beslutIngaUtbildningar',
  beslutIngaStudieperioder: 'epi:beslutIngaStudieperioder',
  omfattning: 'epi:omfattning',
  beslutGodkandStudieforsakran: 'epi:beslutGodkandStudieforsakran',
  beslutNoMoreInfo: 'epi:beslutNoMoreInfo',
  csnExternalLink: 'epi:csnExternalLink',
  csnExternalLinkText: 'epi:csnExternalLinkText',
}
