export interface CSNCommonEpiContent {
  checklistorLinkText: string
  headerText: string
  leadText: string
  csnButtonLink: string
  copyAnsokanIdButtonText: string
  copyAnsokanIdToaster: string
}

export const defaultCSNEpiContent: CSNCommonEpiContent = {
  checklistorLinkText: 'epi:checklistorLinkText',
  headerText: 'epi:headerText',
  leadText: 'epi:leadText',
  csnButtonLink: 'epi:csnButtonLink',
  copyAnsokanIdButtonText: 'epi:copyAnsokanIdButtonText',
  copyAnsokanIdToaster: 'epi:copyAnsokanIdToaster',
}
