import { StudiestodAnsokanState } from '@local/src/services/SwaggerApi/data-contracts'
import React from 'react'
import { Chip } from '@mui/material'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'

interface Props {
    status: StudiestodAnsokanState
}

const StudiestodStatusChip = ({ status }: Props) => {
    const { tCommon } = useCommonTranslation()

    const chipColor = status === StudiestodAnsokanState.Beslutad ? 'filled' : 'outlined'

    const StatusLabels: Record<number, string> = {
        2: 'ny',
        3: 'handlaggs',
        4: 'handlaggs',
        5: 'handlaggs',
        6: 'handlaggs',
        7: 'godkand',
        8: 'ejAktuell',
    }

    return (
        <Chip
            variant={chipColor}
            color="primary"
            label={tCommon('status', 'status', { context: StatusLabels[status] })}
            data-testid={`studiestod-status-chip-${StatusLabels[status]}`}
        />
    )
}

export default StudiestodStatusChip
