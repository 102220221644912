import { InputField } from '@local/src/components/FormComponents'
import React from 'react'
import { veckorQuantity } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Helpers/veckorQuantity'
import { Box, Typography } from '@mui/material'
import { useRadgivarensUnderlagFormContext } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Hooks'
import { useTranslation } from 'react-i18next'

interface Props {
    kalenderhalvarIndex: number
    utbildningsIndex: number
    isReadonly: boolean
}

const Studieersattning = ({ kalenderhalvarIndex, utbildningsIndex, isReadonly }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.radgivarensUnderlag' })

    const { watch } = useRadgivarensUnderlagFormContext()
    const disableStudieersattningField = !watch(`utbildningar.${utbildningsIndex}.studiestodTyp.hasStudieersattning`)
    const studieersattningValue = watch(
        `utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`
    )

    // TODO: array value is not properly updated by React Hook Form, need to pass from outside for now
    return (
        <Box flex={1}>
            <Typography mb={1}>{t('preliminarStudieersattningText')}</Typography>
            <InputField
                name={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`}
                inputType="number"
                label={t('kronorPerDagText')}
                disabled={disableStudieersattningField || isReadonly}
                testId={`utbildningar.${utbildningsIndex}.studieersattningAndStudielon.kalenderhalvar.${kalenderhalvarIndex}.studieersattning`}
            />
            <Typography>
                {veckorQuantity(studieersattningValue)} {t('kronorPerFyraVeckorText')}
            </Typography>
        </Box>
    )
}

export default Studieersattning
