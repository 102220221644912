/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdHocYttrandeApiModel {
    /** @format uuid */
    id?: string
    /** @format uuid */
    begaranId?: string | null
    personnummer?: string | null
    /** @format date-time */
    datumAnsokanOo: string
    kompletterandeBidrag: boolean
    malMedStudier?: string | null
    starksFramtidaStallning: StarksFramtidaStallning
    /** @format int32 */
    orsakStarksFramtidaStallning?: number | null
    orsakMotivering?: string | null
    utbildningar?: AdHocYttrandeUtbildningApiModel[] | null
}

export interface AdHocYttrandeCsnUtbildningApiModel {
    utbildningskod?: string | null
    utbildningsnamn?: string | null
    utbildningsort?: string | null
    skolnamn?: string | null
    skolkod?: string | null
    ospecificeradUtbildning?: boolean
    kurserUtbildning?: OspecificeradKursApiModel[] | null
    uppdragsutbildning?: boolean
}

export interface AdHocYttrandeEjCsnUtbildningApiModel {
    ort?: string | null
    utbildningsanordnare?: string | null
    utbildningsnamn?: string | null
    /** @format int32 */
    antalVeckor?: number
    /** @format int32 */
    omfattning?: number
    organisationsnamn?: string | null
    organisationsnummer?: string | null
    kontaktperson?: string | null
    epostadress?: string | null
    telefonnummer?: string | null
}

export interface AdHocYttrandeKoptUtbildningApiModel {
    /** @format int32 */
    antalKoptaVeckor?: number
    /** @format int32 */
    omfattningKoptaVeckor?: number
    starthalvar?: string | null
}

/** @format int32 */
export enum AdHocYttrandeStatus {
    Undefined = 0,
    UnderBedomning = 1,
    SkickadTillCsn = 2,
}

export interface AdHocYttrandeUtbildningApiModel {
    specifikArbetsgivare?: boolean | null
    tillhandahallasAvArbetsgivare?: TillhandahallasAvArbetsgivare
    slutfordTidigare?: boolean | null
    vasentligSkillnad?: boolean | null
    csnUtbildning?: AdHocYttrandeCsnUtbildningApiModel
    ejCsnUtbildning?: AdHocYttrandeEjCsnUtbildningApiModel
    koptUtbildning?: AdHocYttrandeKoptUtbildningApiModel
}

export interface AgeInkomstBasbeloppApiModel {
    /** @format date-time */
    giltigFran?: string
    /** @format date-time */
    giltigTill?: string
    /** @format double */
    ageDagarPerManad?: number
    /** @format double */
    ageIbbFaktor?: number
    /** @format int32 */
    inkomstBasbelopp?: number
}

/** @format int32 */
export enum Anledning {
    Annat = 0,
    EjCSNBerattigad = 1,
    EkonomiskSituation = 2,
    PersonligSituation = 3,
}

export interface AntalDagarApiModel {
    /** @format int32 */
    maxPerTermin?: number
    /** @format int32 */
    minPerTermin?: number
    /** @format double */
    perManad?: number
}

export interface BegaranCsnYttrandeApiModel {
    /** @format uuid */
    idBegaran?: string | null
    /** @format uuid */
    grundansokanId?: string | null
    /** @format date-time */
    tidpunktBegaran?: string | null
    orsakBegaran?: string | null
    personnummer?: string | null
    kundnamn?: string | null
    epostadress?: string | null
    mobilnummer?: string | null
    malMedStudier?: string | null
    arbetsgivare?: CsnArbetsgivareApiModel[] | null
    utbildningar?: CsnUtbildningYttrandeApiModel[] | null
}

export interface BegaranCsnYttrandeDto {
    /** @format uuid */
    idBegaran?: string
    /** @format uuid */
    grundansokanId?: string
    /** @format date-time */
    tidpunktBegaran?: string
    orsakBegaran?: string | null
    personnummer?: string | null
    kundnamn?: string | null
    epostadress?: string | null
    mobilnummer?: string | null
    malMedStudier?: string | null
    arbetsgivare?: CsnArbetsgivareDto[] | null
    utbildningar?: CsnUtbildningYttrandeDto[] | null
}

export interface BegaranCsnYttrandeListItemApiModel {
    /** @format uuid */
    idBegaran?: string | null
    /** @format uuid */
    grundansokanId?: string | null
    /** @format date-time */
    tidpunktBegaran?: string | null
    orsakBegaran?: string | null
    personnummer?: string | null
    kundnamn?: string | null
    epostadress?: string | null
    mobilnummer?: string | null
    malMedStudier?: string | null
    arbetsgivare?: CsnArbetsgivareApiModel[] | null
    utbildningar?: CsnUtbildningYttrandeApiModel[] | null
    /** @format uuid */
    ansokanId?: string | null
    /** @format uuid */
    adHocYttrandeId?: string | null
    shouldSendToCsn?: boolean
    /** @format date-time */
    lastDateToSvara?: string
    status?: BegaranYttrandeStatus
    lastDateToSvaraExtension?: UserActionTimestampApiModel
}

export interface BegaranKompletterandeBidragApiModel {
    /** @format uuid */
    idBegaran?: string
    /** @format uuid */
    grundansokanId?: string
    /** @format date-time */
    tidpunktBegaran?: string
    beslutsperioder?: CsnBeslutsperiodKompletterandeBidragApiModel[] | null
}

/** @format int32 */
export enum BegaranYttrandeStatus {
    Undefined = 0,
    Ny = 1,
    UnderBedomning = 2,
    Skickad = 3,
    BesvaradWithAdhocYttrande = 4,
}

export interface BeraknadUtbetalningsstartApiModel {
    /** @format int32 */
    year?: number | null
    termin?: Termin
}

export interface BeslutChangeLogApiModel {
    /** @format date-time */
    occuredAt?: string
    baseProperties?: ChangeRecordApiModel[] | null
    beslutPeriodChangeLogList?: GroupChangeLogListItemApiModel[] | null
    utbildningChangeLogList?: GroupChangeLogListItemApiModel[] | null
}

export interface BeslutStudiestodApiModel {
    /** @format uuid */
    beslutatAv?: string | null
    /** @format date-time */
    beslutsDatum?: string | null
    beslutatAvUser?: UserProfileBeslutApiModel
}

export interface BeslutStudiestodDto {
    /** @format uuid */
    beslutatAv?: string | null
    /** @format date-time */
    beslutsDatum?: string | null
    userProfileMeta?: UserProfileMetaDto
}

/** @format int32 */
export enum BeslutsPeriodStudieforsakran {
    Undefined = 0,
    Godkand = 1,
    EjGodkand = 2,
}

/** @format int32 */
export enum ChangeOperation {
    Undefined = 0,
    Updated = 1,
    Created = 2,
    Deleted = 3,
}

export interface ChangeRecordApiModel {
    propertyName?: string | null
    prevValue?: string | null
    newValue?: string | null
    /** @format date-time */
    occuredAt?: string
    changeOperation?: ChangeOperation
    parentRecord?: ParentRecordApiModel
}

export interface CreateAdHocYttrandeApiModel {
    yttrande: AdHocYttrandeApiModel
    /** @format uuid */
    ansokanId: string
}

export interface CreateAnsokanApiModel {
    utbildningsforutsattning?: string | null
    motivering?: MotiveringApiModel
    harIntygatUppgifter?: boolean | null
    villkor?: string | null
    ansokanNamn?: string | null
    utbildningar?: UtbildningApiModel[] | null
}

export interface CsnAdHocYttrandeApiModel {
    yttrande?: AdHocYttrandeApiModel
    /** @format date-time */
    sentToCsnAt?: string | null
    shouldSendToCsn?: boolean
    /** @format uuid */
    ansokanId?: string
    studiestodMeta?: StudiestodMetaApiModel
    lastUpdatedBy?: UserTimestampApiModel
    createdBy?: UserTimestampApiModel
    sentToCsnBy?: UserTimestampApiModel
    status?: AdHocYttrandeStatus
}

export interface CsnArbetsgivareApiModel {
    organisationsnummer?: string | null
}

export interface CsnArbetsgivareDto {
    organisationsnummer?: string | null
}

export interface CsnArendeApiModel {
    /** @format uuid */
    grundansokanId?: string | null
    /** @format uuid */
    ansokanId?: string | null
    /** @format uuid */
    id?: string
    klient?: CsnArendeKlientApiModel
    /** @format date-time */
    createdAt?: string
    csnEvents?: CsnArendeEventApiModel[] | null
    csnBegaranYttrande?: CsnYttrandeApiModel[] | null
    csnKompletterandeBidrag?: KompletterandeBidragApiModel[] | null
}

export interface CsnArendeEventApiModel {
    /** @format uuid */
    id?: string
    csnArendeEventType?: CsnArendeEventType
    takeAction?: boolean
    /** @format date-time */
    time?: string
}

/** @format int32 */
export enum CsnArendeEventType {
    Undefined = 0,
    AdHocYttrandeAdded = 1,
    CsnBegaranYttrandeAdded = 2,
    SvarBegaranYttrandeSaved = 3,
    CsnBeslutAdded = 4,
    CsnKompletterandeBidragAdded = 5,
    SvarKompletterandeBidragSaved = 6,
    AdHocYttrandeDeleted = 7,
}

export interface CsnArendeKlientApiModel {
    /** @format uuid */
    klientId?: string | null
    personnummer?: string | null
}

export interface CsnBeslutDto {
    /** @format uuid */
    id?: string
    /** @format uuid */
    grundansokanId?: string
    /** @format uuid */
    ooReferensnr?: string | null
    /** @format uuid */
    studiestodId?: string | null
    /** @format date-time */
    tidpunktBeslutEllerUtbetalning?: string
    beslutstyp?: CsnBeslutstyp
    beslutsutfall?: CsnBeslutsutfall
    /** @format int32 */
    arligInkomst?: number
    /** @format int32 */
    arligInkomstMotsvarandeHeltidsarbete?: number | null
    utbildningar?: CsnUtbildningBeslutDto[] | null
    beslutsperioder?: CsnBeslutsperiodBeslutDto[] | null
    studiestodMeta?: StudiestodMetaDto
    userProfileCsnBeslut?: UserProfileMetaDto
    /** @format date-time */
    receivedAt?: string
    /** @format date-time */
    createdAt?: string
    /** @format date-time */
    lastUpdatedAt?: string | null
    registeredPeriodInFenixIsDeleted?: boolean
}

export interface CsnBeslutsperiodBeslutApiModel {
    periodId?: string | null
    fromVecka?: string | null
    tomVecka?: string | null
    /** @format int32 */
    omfattningBidrag?: number
    /** @format int32 */
    bidragsBelopp?: number
    godkandStudieforsakran?: boolean | null
    isRegisteredInFenix?: boolean
    hasBeenUpdatedAfterRegistrationInFenix?: boolean
}

export interface CsnBeslutsperiodBeslutDto {
    id?: string | null
    fromVecka?: string | null
    tomVecka?: string | null
    /** @format int32 */
    omfattningBidrag?: number
    /** @format int32 */
    bidragsBelopp?: number
    godkandStudieforsakran?: CsnBool
    isRegisteredInFenix?: boolean
    hasBeenUpdatedAfterRegistrationInFenix?: boolean
}

export interface CsnBeslutsperiodKompletterandeBidragApiModel {
    fromVecka?: string | null
    tomVecka?: string | null
}

/** @format int32 */
export enum CsnBeslutstyp {
    Undefined = 0,
    Slutligt = 1,
    Interimistiskt = 2,
}

/** @format int32 */
export enum CsnBeslutsutfall {
    Undefined = 0,
    Bifall = 1,
    Avslag = 2,
    Avvisad = 3,
    Avskriven = 4,
    Avslutad = 5,
}

export interface CsnBool {
    value?: boolean
}

/** @format int32 */
export enum CsnOrsakBegaran {
    Undefined = 0,
    YttrandeSaknas = 1,
    SoktForAndraStudierAnTidigareYttrandeFranOo = 2,
    SoktUtanforOosRamForKoptUtbildning = 3,
}

export interface CsnRegisterUtbildningApiModel {
    ort?: string | null
    utbildningskod?: string | null
    utbildningsnamn?: string | null
    utbildningsort?: string | null
    ospecificeradUtbildning?: boolean
    skolkod?: string | null
    skolnamn?: string | null
}

export interface CsnUtbildningApiModel {
    skolkod?: string | null
    utbildningskod?: string | null
}

export interface CsnUtbildningBeslutApiModel {
    koptUtbildning?: boolean | null
    ort?: string | null
    utbildningsanordnare?: string | null
    utbildningsnamn?: string | null
    csnUtbildning?: CsnUtbildningApiModel
}

export interface CsnUtbildningBeslutDto {
    koptUtbildning?: CsnBool
    ort?: string | null
    utbildningsanordnare?: string | null
    utbildningsnamn?: string | null
    csnUtbildning?: CsnUtbildningKoderDto
}

export interface CsnUtbildningKoderDto {
    skolkod?: string | null
    utbildningskod?: string | null
}

export interface CsnUtbildningYttrandeApiModel {
    /** @format uuid */
    idUtbildning?: string | null
    ort?: string | null
    /** @format int32 */
    skolkod?: number | null
    utbildningsanordnare?: string | null
    /** @format int32 */
    utbildningskod?: number | null
    utbildningsnamn?: string | null
    franCsnUr?: boolean | null
    ansoktKoptUtbildning?: boolean | null
    /** @format int32 */
    ansoktAntalKoptaVeckor?: number | null
    /** @format double */
    ansoktOmfattningKoptaVeckor?: number | null
    ansoktOmfattningsenhetKoptaVeckor?: string | null
    ansoktStarthalvar?: string | null
    kurserUtbildning?: OspecificeradKursApiModel[] | null
}

export interface CsnUtbildningYttrandeDto {
    /** @format uuid */
    idUtbildning?: string
    ort?: string | null
    /** @format int32 */
    skolkod?: number | null
    utbildningsanordnare?: string | null
    /** @format int32 */
    utbildningskod?: number | null
    utbildningsnamn?: string | null
    franCsnUr?: CsnBool
    ansoktKoptUtbildning?: CsnBool
    /** @format int32 */
    ansoktAntalKoptaVeckor?: number | null
    /** @format double */
    ansoktOmfattningKoptaVeckor?: number | null
    ansoktOmfattningsenhetKoptaVeckor?: string | null
    ansoktStarthalvar?: string | null
    kurserUtbildning?: OspecificeradKursDto[] | null
}

export interface CsnYttrandeApiModel {
    begaranYttrande?: BegaranCsnYttrandeApiModel
    svarBegaranYttrande?: SvarCsnYttrandeApiModel
    /** @format uuid */
    adHocYttrandeId?: string | null
    /** @format uuid */
    ansokanId?: string | null
    /** @format date-time */
    sentToCsnAt?: string | null
    shouldSendToCsn?: boolean
    /** @format date-time */
    lastDateToSvara?: string
    status?: BegaranYttrandeStatus
    lastDateToSvaraExtension?: UserActionTimestampApiModel
}

export interface CsnYttrandeBeslutApiModel {
    /** @format uuid */
    id?: string
    /** @format uuid */
    grundansokanId?: string
    /** @format uuid */
    ooReferensnr?: string | null
    /** @format uuid */
    studiestodId?: string | null
    /** @format date-time */
    tidpunktBeslutEllerUtbetalning?: string
    /** @format date-time */
    inkomFranCsn?: string
    /** @format date-time */
    beslutSenastUppdaterat?: string | null
    registeredPeriodInFenixIsDeleted?: boolean
    beslutstyp?: CsnBeslutstyp
    beslutsutfall?: CsnBeslutsutfall
    /** @format int32 */
    arligInkomst?: number
    /** @format int32 */
    arligInkomstMotsvarandeHeltidsarbete?: number | null
    utbildningar?: CsnUtbildningBeslutApiModel[] | null
    beslutsperioder?: CsnBeslutsperiodBeslutApiModel[] | null
    studiestodMeta?: StudiestodMetaApiModel
    userProfileCsnBeslut?: UserProfileExtendedApiModel
}

/** @format int32 */
export enum CsnYttrandeKoppladFilter {
    Undefined = 0,
    Alla = 1,
    Kopplad = 2,
    Okopplad = 3,
}

export interface DomainEventLogApiModel {
    /** @format uuid */
    id?: string
    subject?: string | null
    action?: string | null
    source?: string | null
    /** @format date-time */
    time?: string
    metadata?: Record<string, string | null>
    name?: string | null
}

export interface EjAktuellApiModel {
    orsakMedarbetare?: string | null
    orsakKund?: string | null
}

export interface EjAktuellDto {
    orsakMedarbetare?: string | null
    orsakKund?: string | null
}

export interface EkonomisktStudiestodApiRequestModel {
    /** @format int32 */
    salary?: number
}

export interface EkonomisktStudiestodApiResponseModel {
    /** @format int32 */
    studietakt?: number
    /** @format int32 */
    ossPerVecka?: number
    /** @format int32 */
    ossPerManad?: number
    /** @format int32 */
    kssPerVecka?: number
    /** @format int32 */
    kssPerManad?: number
    /** @format int32 */
    totalVecka?: number
    /** @format int32 */
    totalManad?: number
}

export interface ErsattningProcentApiModel {
    /** @format int32 */
    termin1?: number
    /** @format int32 */
    termin2?: number
    /** @format int32 */
    termin3?: number
    /** @format int32 */
    termin4?: number
}

export interface Facet {
    name?: string | null
    /** @format int32 */
    count?: number
}

export interface FacetList {
    name?: string | null
    facet?: Facet[] | null
}

export interface FribeloppIntervallApiModel {
    /** @format int32 */
    dagFran?: number
    /** @format int32 */
    dagTill?: number
    fribelopp?: ProcentsatserApiModel
}

export interface GroupChangeLogListItemApiModel {
    displayName?: string | null
    groupChange?: ChangeOperation
    changes?: ChangeRecordApiModel[] | null
}

/** @format int32 */
export enum IsKoptUtbildning {
    VetEj = 0,
    Ja = 1,
    Nej = 2,
}

export interface KalenderhalvarApiModel {
    /** @format int32 */
    studieersattning?: number | null
    /** @format int32 */
    studielon?: number | null
    hasNoStudielon?: boolean
}

export interface KompletterandeBidragApiModel {
    begaranKompletterandeBidrag?: BegaranKompletterandeBidragApiModel
    svarKompletterandeBidrag?: SvarKompletterandeBidragApiModel
    latestSapAttempt?: SapAttemptApiModel
    /** @format int32 */
    numberOfSapAttempts?: number
    /** @format date-time */
    sentToCsnAt?: string | null
    isEnrichedBySap?: boolean
    shouldSendToCsn?: boolean
}

export interface KompletterandeBidragWithUserProfileMetaApiModel {
    begaranKompletterandeBidrag?: BegaranKompletterandeBidragApiModel
    svarKompletterandeBidrag?: SvarKompletterandeBidragApiModel
    latestSapAttempt?: SapAttemptApiModel
    /** @format int32 */
    numberOfSapAttempts?: number
    /** @format date-time */
    sentToCsnAt?: string | null
    isEnrichedBySap?: boolean
    shouldSendToCsn?: boolean
    userProfileKompletterandeBidrag?: UserProfileExtendedApiModel
}

export interface KompletterandeStudiestodApiModel {
    /** @format int32 */
    utbildningslangd?: number | null
    /** @format int32 */
    preliminarErsattning?: number | null
    beraknadUtbetalningsstart?: BeraknadUtbetalningsstartApiModel
}

export interface KoptUtbildningApiModel {
    /** @format int32 */
    kostnad?: number | null
}

export interface KortvarigtStudiestodApiModel {
    /** @format double */
    utbildningslangd?: number | null
    /** @format int32 */
    preliminarErsattning?: number | null
}

/** @format int32 */
export enum KundTillhorTrr {
    Undefined = 0,
    KundTillhorTrr = 1,
    ArbetsgivareTillhorEjTrr = 2,
    Utforsakrad = 3,
}

export interface MotiveringApiModel {
    nyanstallning?: string | null
    arbetsmarknad?: string | null
    kompletteringErfarenhet?: string | null
    utbildningsalternativ?: string | null
}

export interface MotiveringStudielonApiModel {
    anledning?: Anledning
    beskrivning?: string | null
}

export interface NekaStodApiModel {
    isStodNekat?: boolean
    orsak?: string | null
}

export interface OmstallningsinsatsTypApiModel {
    hasUtbildningskostnader?: boolean
    hasEnstakaResor?: boolean
    hasRegelbundnaResor?: boolean
    hasLogi?: boolean
    hasProgramvaror?: boolean
}

export interface OspecificeradKursApiModel {
    kurs?: string | null
}

export interface OspecificeradKursDto {
    kurs?: string | null
}

export interface ParentRecordApiModel {
    id?: string | null
    propertyName?: string | null
    displayName?: string | null
}

export interface ProblemDetails {
    type?: string | null
    title?: string | null
    /** @format int32 */
    status?: number | null
    detail?: string | null
    instance?: string | null
    [key: string]: any
}

export interface ProcentsatserApiModel {
    /** @format int32 */
    belopp100?: number
    /** @format int32 */
    belopp75?: number
    /** @format int32 */
    belopp50?: number
}

/** @format int32 */
export enum ResetState {
    Beslut = 0,
    Underlag = 1,
    EjRedigerbarAnsokan = 2,
    HandlaggAnsokan = 3,
}

export interface SapAttemptApiModel {
    /** @format date-time */
    occurredAt?: string
    shouldTryAgain?: boolean
    /** @format int32 */
    lonForBerakning?: number | null
    responseType?: SapResponseType
    errorMessage?: string | null
}

/** @format int32 */
export enum SapResponseType {
    Undefined = 0,
    Success = 1,
    FailedToFindAnsokan = 2,
    FailedToFindCsnLon = 3,
    InputValidationError = 4,
    UnhandledException = 5,
    ServiceUnavailable = 6,
    LonNotEqual = 7,
}

export interface SearchBeslutRequestApiModel {
    /** @format date-time */
    fromDate?: string | null
    csnBeslutsutfall?: CsnBeslutsutfall
    studieforsakran?: BeslutsPeriodStudieforsakran
    personnummer?: string | null
    /** @format int32 */
    page?: number
    /** @format int32 */
    pageSize?: number
}

export interface SearchBeslutResponseApiModel {
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
    /** @format int32 */
    pageNumber?: number
    facets?: FacetList[] | null
    values?: CsnYttrandeBeslutApiModel[] | null
}

export interface SearchYttrandenRequestApiModel {
    searchYttrandenType?: SearchYttrandenType
    searchYttrandenStatus?: SearchYttrandenStatus
    personnummer?: string | null
    orderBy?: string | null
    /** @format int32 */
    page?: number
    /** @format int32 */
    pageSize?: number
}

export interface SearchYttrandenResponseApiModel {
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
    /** @format int32 */
    pageNumber?: number
    facets?: FacetList[] | null
    values?: YttrandeSearchIndexApiModel[] | null
}

/** @format int32 */
export enum SearchYttrandenStatus {
    Undefined = 0,
    Ny = 1,
    UnderBedomning = 2,
    SkickadTillCsn = 3,
    BesvaradWithAdhocYttrande = 4,
}

/** @format int32 */
export enum SearchYttrandenType {
    Undefined = 0,
    AdHocYttranden = 1,
    BegaranYttranden = 2,
}

/** @format int32 */
export enum StarksFramtidaStallning {
    Undefined = 0,
    Ja = 1,
    Nej = 2,
    Avstar = 3,
}

export interface StudieansokanMinimalInfoApiModel {
    /** @format uuid */
    ansokanId?: string
    /** @format uuid */
    arendeId?: string
    /** @format int32 */
    studiestodNummer?: number | null
    ansokanNamn?: string | null
    /** @format date-time */
    skapatDatum?: string | null
    /** @format date-time */
    publiceringsdatum?: string | null
}

export interface StudieersattningAndStudielonApiModel {
    motiveringStudielon?: MotiveringStudielonApiModel
    kalenderhalvar?: KalenderhalvarApiModel[] | null
    beraknadUtbetalningsstart?: BeraknadUtbetalningsstartApiModel
}

export interface StudieersattningParametrarApiModel {
    /** @format date-time */
    giltigFran?: string
    /** @format date-time */
    giltigTill?: string
    antalDagar?: AntalDagarApiModel
    ersattningProcent?: ErsattningProcentApiModel
    bidrag?: ProcentsatserApiModel
    lan?: ProcentsatserApiModel
    ageInkomstBasbelopp?: AgeInkomstBasbeloppApiModel[] | null
    fribeloppInterval?: FribeloppIntervallApiModel[] | null
}

export interface StudieintygListApiModel {
    /** @format uuid */
    dokumentId?: string
    fileName?: string | null
    originalFileName?: string | null
    /** @format date-time */
    uploadedAt?: string
    sammanhangandeStudier?: string | null
}

/** @format int32 */
export enum StudiestodAnsokanState {
    Sparad = 1,
    Publicerad = 2,
    Handlaggs = 3,
    Last = 4,
    UnderlagSparad = 5,
    UnderlagPublicerad = 6,
    Beslutad = 7,
    EjAktuell = 8,
    Deleted = 9,
}

export interface StudiestodApiModel {
    /** @format uuid */
    id?: string
    /** @format uuid */
    klientId?: string
    klient?: UserProfileExtendedApiModel
    /** @format uuid */
    arendeId?: string
    isStudiestodAnsokanUnderlagBeslutDigitalt?: boolean
    ansokanNamn?: string | null
    /** @format int32 */
    studiestodNummer?: number | null
    utbildningsforutsattning?: string | null
    motivering?: MotiveringApiModel
    harIntygatUppgifter?: boolean | null
    /** @format date-time */
    skapatDatum?: string
    /** @format date-time */
    senastUppdateradDatum?: string
    /** @format date-time */
    ejRedigerbarDatum?: string | null
    isRedigerbar?: boolean
    /** @format date-time */
    publiceringsdatum?: string | null
    isPublicerad?: boolean
    isUppdateradEfterPublicering?: boolean
    villkor?: string | null
    isHandlaggs?: boolean
    isBeslutad?: boolean
    isLegacyAnsokan?: boolean
    studiestodAnsokanState?: StudiestodAnsokanState
    isLegacyUnderlag?: boolean
    beslut?: BeslutStudiestodApiModel
    utbildningar?: UtbildningApiModel[] | null
    showOnlyStudiestodsTypStudieersattningAndStudielon?: boolean
    isForbiddenToUnlockForEditing?: boolean
    canBeSetToEjAktuell?: boolean
    ejAktuell?: EjAktuellApiModel
    hasFakturor?: boolean
    hasStudieersattning?: boolean
    radgivarunderlag?: UnderlagApiModel
}

export interface StudiestodListApiModel {
    /** @format uuid */
    id?: string
    /** @format uuid */
    arendeId?: string
    isStudiestodAnsokanUnderlagBeslutDigitalt?: boolean
    utbildningsforutsattning?: string | null
    /** @format date-time */
    senastUppdateradDatum?: string
    /** @format date-time */
    publiceringsdatum?: string | null
    ansokanNamn?: string | null
    /** @format int32 */
    studiestodNummer?: number | null
    ejAktuell?: EjAktuellApiModel
    isPublicerad?: boolean
    isRedigerbar?: boolean
    isUppdateradEfterPublicering?: boolean
    isHandlaggs?: boolean
    isBeslutad?: boolean
    hasStudieintyg?: boolean
    hasKoptUtbildning?: boolean
    isLegacyAnsokan?: boolean
    isDeletable?: boolean
    hasCsnItems?: boolean
    utbildningar?: UtbildningarListApiModel[] | null
    status?: StudiestodAnsokanState
}

export interface StudiestodMetaApiModel {
    ansokanNamn?: string | null
    /** @format int32 */
    studiestodNummer?: number | null
    /** @format uuid */
    ansokanId?: string
    /** @format uuid */
    arendeId?: string
}

export interface StudiestodMetaDto {
    ansokanNamn?: string | null
    /** @format int32 */
    studiestodNummer?: number | null
    /** @format uuid */
    ansokanId?: string
    /** @format uuid */
    arendeId?: string
}

/** @format int32 */
export enum StudiestodStateAction {
    Undefined = 0,
    Updated = 1,
    Published = 2,
    Locked = 3,
    Unlocked = 4,
    Moved = 5,
}

export interface StudiestodTypApiModel {
    hasStudieersattning?: boolean
    hasStudielon?: boolean
    hasKoptUtbildning?: boolean
    hasKortvarigtStudiestod?: boolean
    hasKompletterandeStudiestod?: boolean
}

/** @format int32 */
export enum StudiestodUnlockState {
    Unknown = 0,
    Ansokan = 1,
    Radgivarunderlag = 2,
    Beslut = 3,
}

export interface StudiestodV2AnsokanDto {
    ansokanNamn?: string | null
    villkor?: string | null
    /** @format date-time */
    senastUppdateradDatum?: string
    /** @format date-time */
    publiceringsdatum?: string | null
    utbildningsforutsattning?: Utbildningsforutsattning
    motivering?: StudiestodV2AnsokanMotiveringDto
    utbildningar?: StudiestodV2AnsokanUtbildningDto[] | null
    ejRedigerbarAv?: UserTimestamp
    handlaggsAv?: UserTimestamp
    isRedigerbar?: boolean
}

export interface StudiestodV2AnsokanMotiveringDto {
    arbetsmarknad?: string | null
    kompletteringErfarenhet?: string | null
    nyanstallning?: string | null
    utbildningsalternativ?: string | null
}

export interface StudiestodV2AnsokanUtbildningDto {
    /** @format uuid */
    id?: string
    utbildningsnamn?: string | null
    anordnare?: string | null
    utbildningstyp?: Utbildningstyp
    /** @format date-time */
    studiestart?: string | null
    /** @format int32 */
    omfattning?: number | null
    /** @format int32 */
    antalTerminerStudiestod?: number | null
    antalTerminer?: string | null
    utbildningLangdEnhet?: UtbildningLangdEnhet
    studiemedelsberattigadUtbildning?: boolean | null
    avserSokaCsn?: boolean | null
    ejCsnAnledning?: string | null
    kraverProvning?: boolean | null
    isKoptUtbildning?: IsKoptUtbildning
    /** @format date-time */
    skapatDatum?: string
    /** @format date-time */
    senastUppdateradDatum?: string
}

export interface StudiestodV2ArendeDto {
    /** @format uuid */
    arendeId?: string
    arendeNummer?: string | null
}

export interface StudiestodV2BeraknadUtbetalningsstartDto {
    /** @format int32 */
    year?: number | null
    termin?: Termin
}

export interface StudiestodV2Dto {
    /** @format uuid */
    id?: string
    /** @format int32 */
    studiestodNummer?: number | null
    state?: StudiestodV2StateDto
    arende?: StudiestodV2ArendeDto
    ansokan?: StudiestodV2AnsokanDto
    kund?: UserProfileMetaDto
    underlag?: StudiestodV2UnderlagDto
    beslut?: BeslutStudiestodDto
    ejAktuell?: EjAktuellDto
    studieintyg?: StudiestodV2StudieintygDto
    /** @format date-time */
    createdAt?: string
    isLegacyAnsokan?: boolean
    isLegacyUnderlag?: boolean
}

export interface StudiestodV2InsatserDto {
    hasStudieersattning?: boolean
    hasStudielon?: boolean
    hasKoptUtbildning?: boolean
    hasKortvarigtStudiestod?: boolean
    hasKompletterandeStudiestod?: boolean
    hasUtbildningskostnader?: boolean
    hasEnstakaResor?: boolean
    hasRegelbundnaResor?: boolean
    hasLogi?: boolean
    hasProgramvaror?: boolean
}

export interface StudiestodV2KalenderhalvarDto {
    /** @format int32 */
    studieersattning?: number | null
    /** @format int32 */
    studielon?: number | null
    hasNoStudielon?: boolean
}

export interface StudiestodV2KompletterandeStudiestodDto {
    /** @format int32 */
    utbildningslangd?: number | null
    /** @format int32 */
    preliminarErsattning?: number | null
    beraknadUtbetalningsstart?: StudiestodV2BeraknadUtbetalningsstartDto
}

export interface StudiestodV2KoptUtbildningDto {
    /** @format int32 */
    kostnad?: number | null
}

export interface StudiestodV2KortvarigtStudiestodDto {
    /** @format double */
    utbildningslangd?: number | null
    /** @format int32 */
    preliminarErsattning?: number | null
}

export interface StudiestodV2MotiveringStudielonDto {
    beskrivning?: string | null
    anledning?: Anledning
}

export interface StudiestodV2NekaStodDto {
    isStodNekat?: boolean
    orsak?: string | null
}

export interface StudiestodV2StateAuditTrail {
    /** @format uuid */
    userId?: string
    /** @format date-time */
    createdAt?: string
    state?: StudiestodAnsokanState
    stateAction?: StudiestodStateAction
}

export interface StudiestodV2StateDto {
    currentState?: string | null
    isStudiestodAnsokanUnderlagBeslutDigitalt?: boolean
    isForbiddenToUnlockForEditing?: boolean
    isDeletable?: boolean
    canBeSetToEjAktuell?: boolean
    hasFakturor?: boolean
    hasStudieersattning?: boolean
    studiestodStateAuditTrail?: StudiestodV2StateAuditTrail[] | null
}

export interface StudiestodV2StudieersattningAndStudielonDto {
    motiveringStudielon?: StudiestodV2MotiveringStudielonDto
    kalenderhalvar?: StudiestodV2KalenderhalvarDto[] | null
    beraknadUtbetalningsstart?: StudiestodV2BeraknadUtbetalningsstartDto
}

export interface StudiestodV2StudieintygDto {
    hasStudieintyg?: boolean
}

export interface StudiestodV2UnderlagDto {
    hasAktivitetstod?: boolean | null
    motiveringArbetsmarknad?: string | null
    motiveringUtbildningsmal?: string | null
    sparadAv?: UserTimestamp
    publiceradAv?: UserTimestamp
    utbildningar?: StudiestodV2UnderlagUtbildningDto[] | null
    /** @format date-time */
    senastUppdateratDatum?: string
}

export interface StudiestodV2UnderlagUtbildningDto {
    ansokanUtbildning?: StudiestodV2AnsokanUtbildningDto
    insatser?: StudiestodV2InsatserDto
    studieersattningAndStudielon?: StudiestodV2StudieersattningAndStudielonDto
    koptUtbildning?: StudiestodV2KoptUtbildningDto
    kortvarigtStudiestod?: StudiestodV2KortvarigtStudiestodDto
    kompletterandeStudiestod?: StudiestodV2KompletterandeStudiestodDto
    yttrandeToCsn?: StudiestodV2YttrandeToCsnDto
    nekaStod?: StudiestodV2NekaStodDto
}

export interface StudiestodV2YttrandeToCsnDto {
    hasYttrandeToCsn?: boolean
}

export interface SvarBegaranYttrandeApiModel {
    /** @format uuid */
    grundansokanId: string
    /** @format uuid */
    idBegaran: string
    /** @format uuid */
    ansokanId?: string | null
    kundTillhorTrr: KundTillhorTrr
    yttrande?: YttrandeApiModel
}

export interface SvarCsnYttrandeApiModel {
    /** @format uuid */
    grundansokanId?: string
    /** @format uuid */
    idBegaran?: string
    kundTillhorTrr?: KundTillhorTrr
    yttrande?: YttrandeApiModel
}

export interface SvarKompletterandeBidragApiModel {
    /** @format uuid */
    idBegaran: string
    perioder?: SvarPeriodKompletterandeBidragApiModel[] | null
}

export interface SvarPeriodKompletterandeBidragApiModel {
    fromVecka?: string | null
    tomVecka?: string | null
    /** @format int32 */
    kompletterandeBidragsbelopp100?: number
    /** @format int32 */
    kompletterandeBidragsbelopp75?: number
    /** @format int32 */
    kompletterandeBidragsbelopp60?: number
    /** @format int32 */
    kompletterandeBidragsbelopp50?: number
    /** @format int32 */
    kompletterandeBidragsbelopp40?: number
    /** @format int32 */
    kompletterandeBidragsbelopp20?: number
}

export interface SvarYttrandeEjCsnUtbildningApiModel {
    antalVeckor?: string | null
    /** @format int32 */
    omfattning?: number
    organisationsnamn?: string | null
    organisationsnummer?: string | null
    kontaktperson?: string | null
    epostadress?: string | null
    telefonnummer?: string | null
}

/** @format int32 */
export enum Termin {
    Vartermin = 0,
    Hosttermin = 1,
}

/** @format int32 */
export enum TillhandahallasAvArbetsgivare {
    Undefined = 0,
    Ja = 1,
    Nej = 2,
    Arbetslos = 3,
}

export interface TrrProblemDetails {
    type?: string | null
    title?: string | null
    /** @format int32 */
    status?: number | null
    detail?: string | null
    instance?: string | null
    error?: string | null
    errorType?: string | null
    [key: string]: any
}

export interface TrrValidationErrorField {
    errorCode?: string | null
    errorMessage?: string | null
    propertyName?: string | null
}

export interface TrrValidationProblemDetails {
    type?: string | null
    title?: string | null
    /** @format int32 */
    status?: number | null
    detail?: string | null
    instance?: string | null
    errors?: Record<string, string[]>
    error?: string | null
    errorType?: string | null
    validationErrors?: TrrValidationErrorField[] | null
    [key: string]: any
}

export interface UnderlagApiModel {
    hasAktivitetstod?: boolean | null
    motiveringArbetsmarknad?: string | null
    motiveringUtbildningsmal?: string | null
    utbildningar?: UtbildningarApiModel[] | null
    /** @format date-time */
    publiceringsdatum?: string | null
}

export interface UpdateAdHocYttrandeApiModel {
    /** @format uuid */
    begaranId?: string | null
    /** @format date-time */
    datumAnsokanOo: string
    kompletterandeBidrag: boolean
    malMedStudier?: string | null
    starksFramtidaStallning: StarksFramtidaStallning
    /** @format int32 */
    orsakStarksFramtidaStallning?: number | null
    orsakMotivering?: string | null
    utbildningar?: AdHocYttrandeUtbildningApiModel[] | null
}

export interface UpdateAnsokanApiModel {
    utbildningsforutsattning?: string | null
    motivering?: MotiveringApiModel
    harIntygatUppgifter?: boolean | null
    villkor?: string | null
    ansokanNamn?: string | null
    utbildningar?: UtbildningApiModel[] | null
}

export interface UpdateEjAktuellApiModel {
    orsakMedarbetare?: string | null
    orsakKund?: string | null
    setEjAktuell: boolean
}

export interface UpdateLastDateToSvaraApiModel {
    /** @format date-time */
    lastDateToSvara: string
}

export interface UpdateUnderlagApiModel {
    hasAktivitetstod?: boolean | null
    motiveringArbetsmarknad?: string | null
    motiveringUtbildningsmal?: string | null
    utbildningar: UtbildningUnderlagApiModel[]
}

export interface UserActionTimestampApiModel {
    /** @format uuid */
    userId?: string
    /** @format date-time */
    time?: string
    /** @format date-time */
    createdAt?: string
    userProfileMetaApiModel?: UserProfileMetaApiModel
}

export interface UserProfileBeslutApiModel {
    /** @format uuid */
    userId?: string
    firstName?: string | null
    lastName?: string | null
}

export interface UserProfileExtendedApiModel {
    firstName?: string | null
    lastName?: string | null
    personnummer?: string | null
}

export interface UserProfileMetaApiModel {
    firstName?: string | null
    lastName?: string | null
}

export interface UserProfileMetaDto {
    /** @format uuid */
    userId?: string
    personnummer?: string | null
    lastName?: string | null
    firstName?: string | null
}

export interface UserTimestamp {
    /** @format uuid */
    userId?: string
    /** @format date-time */
    createdAt?: string
}

export interface UserTimestampApiModel {
    /** @format uuid */
    userId?: string
    /** @format date-time */
    lastUpdatedAt?: string
    firstName?: string | null
    lastName?: string | null
}

export interface UtbildningApiModel {
    /** @format uuid */
    id?: string
    utbildningsnamn?: string | null
    anordnare?: string | null
    utbildningstyp?: string | null
    /** @format date-time */
    studiestart?: string | null
    /** @format int32 */
    omfattning?: number | null
    /** @format int32 */
    antalTerminerStudiestod?: number | null
    antalTerminer?: string | null
    utbildningLangdEnhet?: UtbildningLangdEnhet
    studiemedelsberattigadUtbildning?: boolean | null
    avserSokaCsn?: boolean | null
    ejCsnAnledning?: string | null
    kraverProvning?: boolean | null
    isKoptUtbildning?: IsKoptUtbildning
}

/** @format int32 */
export enum UtbildningLangdEnhet {
    Terminer = 0,
    Veckor = 1,
    Dagar = 2,
}

export interface UtbildningSvarYttrandeApiModel {
    /** @format uuid */
    idUtbildning: string
    specifikArbetsgivare?: boolean | null
    tillhandahallasAvArbetsgivare?: TillhandahallasAvArbetsgivare
    slutfordTidigare?: boolean | null
    vasentligSkillnad?: boolean | null
    ooFinansierarKoptUtb?: boolean | null
    ejCsnUtbildning?: SvarYttrandeEjCsnUtbildningApiModel
}

export interface UtbildningUnderlagApiModel {
    /** @format uuid */
    utbildningId?: string
    nekaStod?: NekaStodApiModel
    studieersattningAndStudielon?: StudieersattningAndStudielonApiModel
    koptUtbildning?: KoptUtbildningApiModel
    kompletterandeStudiestod?: KompletterandeStudiestodApiModel
    kortvarigtStudiestod?: KortvarigtStudiestodApiModel
    studiestodTyp?: StudiestodTypApiModel
    omstallningsinsatsTyp?: OmstallningsinsatsTypApiModel
    yttrandeToCsn?: YttrandeToCsnApiModel
}

export interface UtbildningarApiModel {
    utbildning?: UtbildningApiModel
    studiestodTyp?: StudiestodTypApiModel
    yttrandeToCsn?: YttrandeToCsnApiModel
    omstallningsinsatsTyp?: OmstallningsinsatsTypApiModel
    studieersattningAndStudielon?: StudieersattningAndStudielonApiModel
    koptUtbildning?: KoptUtbildningApiModel
    kompletterandeStudiestod?: KompletterandeStudiestodApiModel
    kortvarigtStudiestod?: KortvarigtStudiestodApiModel
    nekaStod?: NekaStodApiModel
}

export interface UtbildningarListApiModel {
    /** @format uuid */
    id?: string
    utbildningsnamn?: string | null
    anordnare?: string | null
    utbildningstyp?: string | null
    /** @format date-time */
    studiestart?: string | null
    /** @format int32 */
    omfattning?: number | null
    isStodNekat?: boolean
}

/** @format int32 */
export enum Utbildningsforutsattning {
    Omskolning = 0,
    Vidareutbildning = 1,
}

/** @format int32 */
export enum Utbildningstyp {
    UniversitetHogskola = 0,
    Yrkeshogskola = 1,
    Arbetsmarknadsutbildning = 2,
    Gymnasieutbildning = 3,
    Annat = 4,
}

export interface YttrandeApiModel {
    /** @format date-time */
    datumAnsokanOo?: string | null
    kompletterandeBidrag: boolean
    starksFramtidaStallning: StarksFramtidaStallning
    /** @format int32 */
    orsakStarksFramtidaStallning?: number | null
    orsakMotivering?: string | null
    utbildningar: UtbildningSvarYttrandeApiModel[]
}

export interface YttrandeArbetsgivareIndexApiModel {
    organisationsnummer?: string | null
}

export interface YttrandeSearchIndexApiModel {
    id?: string | null
    grundansokanId?: string | null
    type?: SearchYttrandenType
    status?: SearchYttrandenStatus
    /** @format date-time */
    createdAt?: string | null
    /** @format date-time */
    sentToCsnAt?: string | null
    /** @format date-time */
    lastDateToSvara?: string | null
    orsakBegaran?: CsnOrsakBegaran
    studieansokanInfo?: YttrandeStudieansokanInfoIndexApiModel
    userInfo?: YttrandeUserInfoIndexApiModel
    arbetsgivare?: YttrandeArbetsgivareIndexApiModel[] | null
    utbildningar?: YttrandeUtbildningIndexApiModel[] | null
}

export interface YttrandeStudieansokanInfoIndexApiModel {
    ansokanId?: string | null
    /** @format int32 */
    studiestodNummer?: number | null
    ansokanNamn?: string | null
    arendeId?: string | null
}

export interface YttrandeToCsnApiModel {
    hasYttrandeToCsn?: boolean
}

export interface YttrandeUserInfoIndexApiModel {
    userId?: string | null
    personnummer?: string | null
    kundnamn?: string | null
}

export interface YttrandeUtbildningIndexApiModel {
    utbildningsnamn?: string | null
    utbildningsanordnare?: string | null
}

export interface CsnAdHocYttrandeUpdateParams {
    shouldSendToCsn?: boolean
    /** @format uuid */
    adHocYttrandeId: string
}

export interface CsnAdHocYttrandeCreateParams {
    shouldSendToCsn?: boolean
}

export interface CsnArendeBegaranYttrandeSvarCreateParams {
    shouldSendToCsn?: boolean
    /** @format uuid */
    grundansokanId: string
    /** @format uuid */
    begaranId: string
}

export interface CsnArendeKlientBegaranYttrandeDetailParams {
    ansokankopplad?: CsnYttrandeKoppladFilter
    personnummer: string
}

export interface CsnArendeBeslutPeriodIsRegisteredPartialUpdateParams {
    isRegisteredInFenix?: boolean
    /** @format uuid */
    grundansokanId: string
    periodId: string
}

export interface CsnUtbildningarListParams {
    searchTerm?: string
}

export interface KlientAnsokanCreateParams {
    shouldPublish?: boolean
    /** @format uuid */
    klientId: string
}

export interface KlientAnsokanDetailParams {
    ejAktuell?: boolean
    /** @format uuid */
    klientId: string
}

export interface KlientAnsokanUpdateParams {
    shouldPublish?: boolean
    /** @format uuid */
    klientId: string
    /** @format uuid */
    id: string
}

export type KlientAnsokanDeletePayload = string

export interface KlientAnsokanStudieintygPartialUpdatePayload {
    AvserAr?: string
    Termin?: string
    SammanhangandeStudier?: string
    Files?: File[]
}

export interface ArendeAnsokanDetailParams {
    ejAktuell?: boolean
    /** @format uuid */
    arendeId: string
}

export interface ArendeAnsokanRadgivarunderlagV2PartialUpdateParams {
    /** @default false */
    shouldPublish?: boolean
    /** @format uuid */
    arendeId: string
    /** @format uuid */
    ansokanId: string
}

export interface ArendeAnsokanRadgivarunderlagPartialUpdateParams {
    /** @default false */
    shouldPublish?: boolean
    /** @format uuid */
    arendeId: string
    /** @format uuid */
    ansokanId: string
}
