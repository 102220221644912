/* eslint-disable @typescript-eslint/no-misused-promises */
import { useDownloadDocumentById, useGetCurrentStudiestodansokan } from '@local/src/features/StudiestodAnsokan/Hooks'
import { formatToDate, formatToTime } from '@local/src/utils/helpers/dateTimeFormatHelper'
import { StudieintygListApiModel } from '@local/src/services/SwaggerApi/data-contracts'
import { isEmpty } from 'ramda'
import React from 'react'
import { Chip, Typography, Stack, Link, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export enum SammanhangandeStudierEnum {
    KONTINUERLIGASTUDIER = 'KontinuerligaStudier',
    JULLEDIGHET = 'Julledighet',
    SOMMARLEDIGHET = 'Sommarledighet',
}
interface Props {
    intyg: StudieintygListApiModel
}
const StudieintygItem = ({ intyg }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.studieintyg' })

    const chipLabel: Record<string, string> = {
        [SammanhangandeStudierEnum.KONTINUERLIGASTUDIER]: t('kontinuerligaStudierText'),
        [SammanhangandeStudierEnum.JULLEDIGHET]: t('julledighetText'),
        [SammanhangandeStudierEnum.SOMMARLEDIGHET]: t('sommarledighetText'),
    }
    const date = formatToDate(intyg.uploadedAt)
    const time = formatToTime(intyg.uploadedAt)
    const { studiestodAnsokan } = useGetCurrentStudiestodansokan()
    const { downloadDocumentById } = useDownloadDocumentById()
    const handleDownloadDocumentClicked = async () =>
        await downloadDocumentById(studiestodAnsokan.arendeId, studiestodAnsokan.id, intyg.dokumentId, intyg.fileName)
    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" flex={1}>
            <Stack>
                <Link
                    component="button"
                    variant="subtitle1"
                    underline="hover"
                    data-testid="studieintyg-list-item-submit"
                    onClick={handleDownloadDocumentClicked}
                >
                    <Typography>{intyg.fileName}</Typography>
                </Link>
                {!isEmpty(intyg.sammanhangandeStudier) && (
                    <Chip
                        variant="filled"
                        color="success"
                        data-testid="sammanhangande-studier-chip"
                        label={chipLabel[intyg.sammanhangandeStudier]}
                    />
                )}
            </Stack>

            <Box>
                <Typography variant="subtitle1">{date}</Typography>
                <Typography variant="subtitle2" textAlign={'right'}>
                    {time}
                </Typography>
            </Box>
        </Stack>
    )
}

export default StudieintygItem
