import React from 'react'
import { PageWrapper } from '@local/src/components/PageWrapper'
import BegartYttrandeListItemCard from '@local/src/features/BegardaYttrandenList/Components/BegartYttrandeListItemCard/BegartYttrandeListItemCard'
import { removeTrailingSlash } from '@local/src/utils/helpers'
import { Button, Stack, Typography } from '@mui/material'
import { useNavigateToPage } from '@local/src/hooks'
import { useTranslation } from 'react-i18next'

import AdHocYttrandeListItemCard from './Components'
import { useGetYttranden } from './Hooks'

interface Props {
    arendeId: string
    ansokanId: string
    isEjAktuell: boolean
}

const Yttranden = ({ arendeId, ansokanId, isEjAktuell }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokan.steps.csn' })

    const { yttranden, isYttrandenFetching, isYttrandenError } = useGetYttranden(arendeId, ansokanId)

    const hasYttranden = !isYttrandenFetching && !isYttrandenError && yttranden.length > 0
    const { navigateOutsideMf } = useNavigateToPage()
    const createAdHocUrl = `${removeTrailingSlash(t('linkToAdHocYttrandeSkapa'))}/arende/${arendeId}/ansokan/${ansokanId}`
    const goToSkapaAdHocYttrande = () => navigateOutsideMf(createAdHocUrl)
    return (
        <PageWrapper
            isLoading={isYttrandenFetching}
            errorLoadingData={isYttrandenError}
            errorText={t('loadingErrorText')}
            headerText={t('headerText')}
        >
            <Typography>{t('descriptionText')}</Typography>

            {!isEjAktuell && (
                <Button
                    data-testid="skapa-ad-hoc-yttrande-button"
                    variant="outlined"
                    onClick={goToSkapaAdHocYttrande}
                    sx={{ mt: 3, mb: 3 }}
                >
                    {t('adHocButtonText')}
                </Button>
            )}
            {hasYttranden ? (
                <Stack spacing={3} data-testid="yttranden-list">
                    {yttranden.map((yttrande) =>
                        yttrande.type === 'BegaranYttrande' ? (
                            <BegartYttrandeListItemCard
                                arendeId={arendeId}
                                isKoppladeToAnsokan
                                yttrande={yttrande}
                                key={yttrande.idBegaran}
                            />
                        ) : (
                            <AdHocYttrandeListItemCard adHocYttrande={yttrande} key={yttrande.yttrande.id} />
                        )
                    )}
                </Stack>
            ) : (
                <Typography marginTop={2} data-testid="no-result-yttranden">
                    {t('noResultText')}
                </Typography>
            )}
        </PageWrapper>
    )
}

export default Yttranden
