export interface KortvarigtStudiestodEpiContent {
  kortvarigtStudiestodHeader: string
  kortvarigtStudiestodLeadText: string
  kortvarigtStudiestodErsattningLink: string
  kortvarigtStudiestodErsattningLinkText: string
  kortvarigtStudiestodUtbildningslangdText: string
  kortvarigtStudiestodUtbildningslangdLabel: string
  kortvarigtStudiestodPreliminarErsattningText: string
}

export interface KortvarigtStudiestodFelMeddelandeEpiContent {
  kortvarigtStudiestodPreliminarErsattningMin: string
  kortvarigtStudiestodPreliminarErsattningMax: string
  kortvarigtStudiestodPreliminarErsattningRequired: string

  kortvarigtStudiestodUtbildningslangdErsattningMin: string
  kortvarigtStudiestodUtbildningslangdErsattningMax: string
  kortvarigtStudiestodUtbildningslangdErsattningRequired: string
}

export const defaultKortvarigtStudiestodFelMeddelandeEpiContent: KortvarigtStudiestodFelMeddelandeEpiContent = {
  kortvarigtStudiestodPreliminarErsattningMin: 'epi:kortvarigtStudiestodPreliminarErsattningMin',
  kortvarigtStudiestodPreliminarErsattningMax: 'epi:kortvarigtStudiestodPreliminarErsattningMax',
  kortvarigtStudiestodPreliminarErsattningRequired: 'epi:kortvarigtStudiestodPreliminarErsattningRequired',
  kortvarigtStudiestodUtbildningslangdErsattningMin: 'epi:kortvarigtStudiestodUtbildningslangdErsattningMin',
  kortvarigtStudiestodUtbildningslangdErsattningMax: 'epi:kortvarigtStudiestodUtbildningslangdErsattningMax',
  kortvarigtStudiestodUtbildningslangdErsattningRequired: 'epi:kortvarigtStudiestodUtbildningslangdErsattningRequired',
}

export const defaultKortvarigtStudiestodEpiContent: KortvarigtStudiestodEpiContent = {
  kortvarigtStudiestodHeader: 'epi:kortvarigtStudiestodHeader',
  kortvarigtStudiestodLeadText: 'epi:kortvarigtStudiestodLeadText',
  kortvarigtStudiestodErsattningLink: 'epi:kortvarigtStudiestodErsattningLink',
  kortvarigtStudiestodErsattningLinkText: 'epi:kortvarigtStudiestodErsattningLinkText',
  kortvarigtStudiestodUtbildningslangdText: 'epi:kortvarigtStudiestodUtbildningslangdText',
  kortvarigtStudiestodPreliminarErsattningText: 'epi:kortvarigtStudiestodPreliminarErsattningText',
  kortvarigtStudiestodUtbildningslangdLabel: 'epi:kortvarigtStudiestodUtbildningslangdLabel',
}
