import { IStudiestodEpiContent } from '@local/src/@types/StudiestodEpiContent'
import { defaultStudierTabCommonContent } from '@local/src/utils/Content/Common'
import { defaultCSNBeslutEpiContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/CSN/Components/BeslutFranCsn/Models/CsnBeslut.content'
import { defaultCSNYttrandenEpiContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/CSN/Components/Yttranden/Models/CsnYttranden.content'
import { defaultCSNEpiContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/CSN/CSN.content'
import {
    defaultKompletterandeStudiestodEpiContent,
    defaultKompletterandeStudiestodFelMeddelandeEpiContent,
} from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/KompletterandeStudiestod/KompletterandeStudiestod.content'
import { defaultKoptUtbildningEpiContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/KoptUtbildning/KoptUtbildning.content'
import {
    defaultKortvarigtStudiestodEpiContent,
    defaultKortvarigtStudiestodFelMeddelandeEpiContent,
} from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/KortvarigtStudiestod/KortvarigtStudiestod.content'
import { defaultStudierExcelRaknesnurrorContent } from '@local/src/features/StudiestodAnsokan/Components/Steps/RadgivarensUnderlag/Components/Insats/Components/StudieersattningStudielon/Components/ExcelLinks/Models/StudierExcelRaknesnurror.content'

export const epiFallbackContent: IStudiestodEpiContent = {
    studiertab: {
        studierExcelRaknesnurrorFile: defaultStudierExcelRaknesnurrorContent,
        studieAnsokan: {
            ansokanOmStudiestod: {
                headerText: 'epi:headerText',
                leadText: 'epi:leadText',
                motiveringText: 'epi:motiveringText',
                arbetsmarknadText: 'epi:arbetsmarknadText',
                kompletteringErfarenhetText: 'epi:kompletteringErfarenhetText',
                nyanstallningText: 'epi:nyanstallningText',
                utbildningsalternativText: 'epi:utbildningsalternativText',
                utbildningsforutsattningText: 'epi:utbildningsforutsattningText',
                utbildningsforutsattningOmskolningText: 'epi:utbildningsforutsattningOmskolningText',
                utbildningsforutsattningVidareutbildningText: 'epi:utbildningsforutsattningVidareutbildningText',
                markeraKlarButtonText: 'epi:markeraKlarButtonText',
                markeraKlarText: 'epi:markeraKlarText',
                utbildningarText: 'epi:utbildningarText',
                klarmarkeradCheckboxText: 'epi:klarmarkeradCheckboxText',
                forsakranText: 'epi:forsakranText',
                studiestodFranTrrTitle: 'epi:studiestodFranTrrTitle',
                antalTerminerStudiestodText: 'epi:antalTerminerStudiestodText',
                informationOmCsnTitle: 'epi:InformationOmCsnTitle',
                studiemedelsberattigadUtbildningText: 'epi:studiemedelsberattigadUtbildningText',
                avserSokaCsnText: 'epi:avserSokaCsnText',
                ejCsnAnledningText: 'epi:ejCsnAnledningText',
                kraverProvningText: 'epi:kraverProvningText',
                ansokanIsLockedForEditingHeader: 'epi:ansokanIsLockedForEditingHeader',
                ansokanIsLockedForEditingDescription: 'epi:ansokanIsLockedForEditingDescription',
                ansokanIsLockedForEditingUnlockButtonText: 'epi:ansokanIsLockedForEditingUnlockButtonText',
                ansokanIsForbiddenToUnlockHeader: 'epi:ansokanIsForbiddenToUnlockHeader',
                ansokanIsForbiddenToUnlockDescription: 'epi:ansokanIsForbiddenToUnlockDescription',
                ansokanUnlockSuccessText: 'epi:ansokanUnlockSuccessText',
                ansokanUnlockFailureText: 'epi: ansokanUnlockFailureText',
                ansokanUnlockAnsokanAndUnderlagSuccessText: 'epi:ansokanUnlockAnsokanAndUnderlagSuccessText',
            },
            csnCommon: defaultCSNEpiContent,
            csnBeslut: defaultCSNBeslutEpiContent,
            csnYttranden: defaultCSNYttrandenEpiContent,
            studieintyg: {
                headerText: 'epi:headerText',
                leadText: 'epi:leadText',
                gdprInfoText: 'epi:gdprInfoText',
                tidigareDokumentInfoText: 'epi:tidigareDokumentInfoText',
                kontinuerligaStudierText: 'epi:kontinuerligaStudierText',
                julledighetText: 'epi:julledighetText',
                sommarledighetText: 'epi:sommarledighetText',
                noStudieintygText: 'epi:noStudieintygText',
                uppladdadAvText: 'epi:uppladdadAvText',
            },
            beslutNyaAvtaletInformation: {
                informationBoxNyaAvtaletTitle: 'epi:informationBoxNyaAvtaletTitle',
                informationBoxNyaAvtaletContent: 'epi:informationBoxNyaAvtaletContent',
                informationBoxNyaAvtaletLink: 'epi:informationBoxNyaAvtaletLink',
                informationBoxNyaAvtaletLinkText: 'epi:informationBoxNyaAvtaletLinkText',
            },
            beslut: {
                headerText: 'epi:headerText',
                godkannAnsokanButtonText: 'epi:godkannAnsokanButtonText',
                godkannAvHeaderText: 'epi:godkannAvHeaderText',
                godkannAvLeadText: 'epi:godkannAvLeadText',
                accordionEntry1Header: 'epi:accordionEntry1Header',
                accordionEntry1Description: 'epi:accordionEntry1Description',
                accordionEntry2Header: 'epi:accordionEntry2Header',
                accordionEntry2Description: 'epi:accordionEntry2Description',
                accordionHeader: 'epi:accordionHeader',
                accordionEntry3Header: 'epi:accordionEntry3Header',
                accordionEntry3SubHeader: 'epi:accordionEntry3SubHeader',
                accordionEntry3Description: 'epi:accordionEntry3Description',
                accordionEntry3List1Header: 'epi:accordionEntry3List1Header',
                accordionEntry3List1Item1: 'epi:accordionEntry3List1Item1',
                accordionEntry3List1Item2: 'epi:accordionEntry3List1Item2',
                accordionEntry3List2Header: 'epi:accordionEntry3List2Header',
                accordionEntry3List2Item1: 'epi:accordionEntry3List2Item1',
                accordionEntry3List2Item2: 'epi:accordionEntry3List2Item2',
                accordionEntry3List2Item3: 'epi:accordionEntry3List2Item3',
                accordionEntry3List2Item4: 'epi:accordionEntry3List2Item4',
                accordionEntry3List2Item5: 'epi:accordionEntry3List2Item5',
                accordionEntry3List2Item6: 'epi:accordionEntry3List2Item6',
                accordionEntry3List2Item7: 'epi:accordionEntry3List2Item7',
                notCompleteDigitalStudiestodTitle: 'epi:notCompleteDigitalStudiestodTitle',
                notCompleteDigitalStudiestodDescription: 'epi:notCompleteDigitalStudiestodDescription',
                infoBoxAfterGodkandText: 'epi:infoBoxAfterGodkandText',
                descriptionText: 'epi:descriptionText',
                canNotTaBeslutInfoMessage: 'epi:canNotTaBeslutInfoMessage',
                beslutIsLockedForEditingHeader: 'epi:beslutIsLockedForEditingHeader',
                beslutIsLockedForEditingDescription: 'epi:beslutIsLockedForEditingDescription',
                beslutIsLockedForEditingUnlockButtonText: 'epi:beslutIsLockedForEditingUnlockButtonText',
                beslutIsForbiddenToUnlockHeader: 'epi:beslutIsForbiddenToUnlockHeader',
                beslutIsForbiddenToUnlockDescription: 'epi:beslutIsForbiddenToUnlockDescription',
                beslutUnlockSuccessText: 'epi:beslutUnlockSuccessText',
                beslutUnlockFailureText: 'epi:beslutUnlockFailureText',
            },
            beslutSpecialfall: {
                beforeBeslutAccordionHeader: 'epi:beforeBeslutAccordionHeader',
                beforeBeslutAccordionEntry1Header: 'epi:beforeBeslutAccordionEntry1Header',
                beforeBeslutAccordionEntry1Description: 'epi:beforeBeslutAccordionEntry1Description',
                beforeBeslutAccordionEntry1DisplayTextEmail: 'epi:beforeBeslutAccordionEntry1DisplayTextEmail',
                beforeBeslutAccordionEntry1Email: 'epi:beforeBeslutAccordionEntry1Email',
                beforeBeslutAccordionEntry2Header: 'epi:beforeBeslutAccordionEntry2Header',
                beforeBeslutAccordionEntry2Description: 'epi:beforeBeslutAccordionEntry2Description',
                beforeBeslutAccordionEntry3Header: 'epi:beforeBeslutAccordionEntry3Header',
                beforeBeslutAccordionEntry3Description: 'epi:beforeBeslutAccordionEntry3Description',
                isEjCsnBerattigadWarningText: 'epi:isEjCsnBerattigadWarningText',
                isStudielonOverstigerMaxbeloppWarningText: 'epi:isStudielonOverstigerMaxbeloppWarningText',
                isKombinationStudieersattningStudielonWarningText: 'epi:isKombinationStudieersattningStudielonWarningText',
                afterBeslutAccordionHeader: 'epi:afterBeslutAccordionHeader',
                afterBeslutAccordionEntry1Header: 'epi:afterBeslutAccordionEntry1Header',
                afterBeslutAccordionEntry1Description: 'epi:afterBeslutAccordionEntry1Description',
                afterBeslutAccordionEntry2Header: 'epi:afterBeslutAccordionEntry2Header',
                afterBeslutAccordionEntry2Description: 'epi:afterBeslutAccordionEntry2Description',
                afterBeslutAccordionEntry3Header: 'epi:afterBeslutAccordionEntry3Header',
                afterBeslutAccordionEntry3Description: 'epi:afterBeslutAccordionEntry3Description',
                afterBeslutAccordionEntry4Header: 'epi:afterBeslutAccordionEntry4Header',
                afterBeslutAccordionEntry4Description: 'epi:afterBeslutAccordionEntry4Description',
                afterBeslutAccordionEntry4SubHeader: 'epi:afterBeslutAccordionEntry4SubHeader',
                afterBeslutAccordionEntry4List1Header: 'epi:afterBeslutAccordionEntry4List1Header',
                afterBeslutAccordionEntry4List1Item1: 'epi:afterBeslutAccordionEntry4List1Item1',
                afterBeslutAccordionEntry4List1Item2: 'epi:afterBeslutAccordionEntry4List1Item2',
                afterBeslutAccordionEntry4List2Header: 'epi:afterBeslutAccordionEntry4List2Header',
                afterBeslutAccordionEntry4List2Item1: 'epi:afterBeslutAccordionEntry4List2Item1',
                afterBeslutAccordionEntry4List2Item2: 'epi:afterBeslutAccordionEntry4List2Item2',
                afterBeslutAccordionEntry4List2Item3: 'epi:afterBeslutAccordionEntry4List2Item3',
                afterBeslutAccordionEntry4List2Item4: 'epi:afterBeslutAccordionEntry4List2Item4',
                afterBeslutAccordionEntry4List2Item5: 'epi:afterBeslutAccordionEntry4List2Item5',
                afterBeslutAccordionEntry4List2Item6: 'epi:afterBeslutAccordionEntry4List2Item6',
                afterBeslutAccordionEntry4List2Item7: 'epi:afterBeslutAccordionEntry4List2Item7',
                specialBeslutModalHeader: 'epi:specialBeslutModalHeader',
                specialBeslutModalDescription: 'epi:specialBeslutModalDescription',
                specialBeslutModalPrimaryButton: 'epi:specialBeslutModalPrimaryButton',
                specialBeslutModalSecondaryButton: 'epi:specialBeslutModalSecondaryButton',
                descriptionText: 'epi:descriptionText',
                submitButtonText: 'epi:submitButtonText',
            },
            radgivarunderlagLankar: {
                kompletterandeStudiestodErsattningLinkText: 'epi:kompletterandeStudiestodErsattningLinkText',
                kompletterandeStudiestodErsattningLink: 'epi:kompletterandeStudiestodErsattningLink',
                kortvarigtStudiestodErsattningLink: 'epi:kortvarigtStudiestodErsattningLink',
                kortvarigtStudiestodErsattningLinkText: 'epi:kortvarigtStudiestodErsattningLinkText',
                koptUtbildningValutaomvandlareLinkText: 'epi:koptUtbildningValutaomvandlareLinkText',
                koptUtbildningValutaomvandlareLink: 'epi:koptUtbildningValutaomvandlareLink',
                koptUtbildningKDBLinkText: 'epi:koptUtbildningKDBLinkText',
                koptUtbildningKDBLink: 'epi:koptUtbildningKDBLink',
            },
            radgivarensUnderlag: {
                header: 'epi:header',
                description: 'epi:description',
                notCompleteDigitalStudiestodTitle: 'epi:notCompleteDigitalStudiestodTitle',
                notCompleteDigitalStudiestodDescription: 'epi:notCompleteDigitalStudiestodDescription',
                preNyaAvtaletHighlightBoxHeader: 'epi:preNyaAvtaletHighlightBoxHeader',
                preNyaAvtaletHighlightBoxText: 'epi:preNyaAvtaletHighlightBoxText',
                motiveringArbetsmarknadText: 'epi:motiveringArbetsmarknadText',
                motiveringArbetsmarknadTooltipText: 'epi:motiveringArbetsmarknadTooltipText',
                motiveringUtbildningsmaletText: 'epi:motiveringUtbildningsmaletText',
                motiveringUtbildningsmaletSubText: 'epi:motiveringUtbildningsmaletSubText',
                motiveringUtbildningsmaletTooltipText: 'epi:motiveringUtbildningsmaletTooltipText',
                motiveringUtbildningsmaletTooltipLinkText: 'epi:motiveringUtbildningsmaletTooltipLinkText',
                motiveringUtbildningsmaletTooltipLink: 'epi:motiveringUtbildningsmaletTooltipLink',

                aktivitetsstodText: 'epi:aktivitetsstodText',
                aktivitetsstodTooltipText: 'epi:aktivitetsstodTooltipText',
                gdprText: 'epi:gdprText',
                gdprLinkText: 'epi:gdprLinkText',
                gdprLink: 'epi:gdprLink',
                submitButtonText: 'epi:submitButtonText',
                draftButtonText: 'epi:draftButtonText',
                draftSubmitDescription: 'epi:draftSubmitDescription',
                insatserHeader: 'epi:insatserHeader',
                insatserDescription: 'epi:insatserDescription',
                universitetHogskolaText: 'epi:universitetHogskolaText',
                yrkeshogskolaText: 'epi:yrkeshogskolaText',
                arbetsmarknadsutbildningText: 'epi:arbetsmarknadsutbildningText',
                gymnasieutbildningText: 'epi:gymnasieutbildningText',
                utbildningstypText: 'epi:utbildningstypText',
                annatText: 'epi:annatText',
                studiestartText: 'epi:studiestartText',
                utbildningslangdText: 'epi:utbildningslangdText',
                utbildningsavgiftText: 'epi:utbildningsavgiftText',
                studietaktText: 'epi:studietaktText',
                enbartYttrandeTillCsnText: 'epi:enbartYttrandeTillCsnText',
                kompletterandeStudiestodAndYttrandeToCsnText: 'epi:kompletterandeStudiestodAndYttrandeToCsnText',
                kortvarigtStudiestodText: 'epi:kortvarigtStudiestodText',
                koptUtbildningText: 'epi:koptUtbildningText',
                studieersattningText: 'epi:studieersattningText',
                studielonText: 'epi:studielonText',
                utbildningskostnaderText: 'epi:utbildningskostnaderText',
                enstakaResorText: 'epi:enstakaResorText',
                regelbundnaResorText: 'epi:regelbundnaResorText',
                logiText: 'epi:logiText',
                programvarorText: 'epi:programvarorText',
                valjStodStudiestodHeader: 'epi:valjStodStudiestodHeader',
                valjStodHeader: 'epi:valjStodHeader',
                valjStodOvrigaInsatserHeader: 'epi:valjStodOvrigaInsatserHeader',
                aktivitetsstodHeader: 'epi:aktivitetsstodHeader',
                aktivitetsstodListEntry1Header: 'epi:aktivitetsstodListEntry1Header',
                aktivitetsstodListEntry1Content: 'epi:aktivitetsstodListEntry1Content',
                aktivitetsstodListEntry1Entry1Header: 'epi:aktivitetsstodListEntry1Entry1Header',
                aktivitetsstodListEntry1Entry1Content1: 'epi:aktivitetsstodListEntry1Entry1Content1',
                aktivitetsstodListEntry1Entry2Header: 'epi:aktivitetsstodListEntry1Entry2Header',
                aktivitetsstodListEntry1Entry2Content1: 'epi:aktivitetsstodListEntry1Entry2Content1',
                aktivitetsstodListEntry1Entry2Content2: 'epi:aktivitetsstodListEntry1Entry2Content2',
                aktivitetsstodListEntry1Entry2Content3: 'epi:aktivitetsstodListEntry1Entry2Content3',
                aktivitetsstodListEntry1Entry3Header: 'epi:aktivitetsstodListEntry1Entry3Header',
                aktivitetsstodListEntry1Entry3Content1: 'epi:aktivitetsstodListEntry1Entry3Content1',
                aktivitetsstodListEntry1Entry3Content2: 'epi:aktivitetsstodListEntry1Entry3Content2',
                aktivitetsstodListEntry1Entry3ContentLink: 'epi:aktivitetsstodListEntry1Entry3ContentLink',
                aktivitetsstodListEntry1Entry3ContentLinkName: 'epi:aktivitetsstodListEntry1Entry3ContentLinkName',
                aktivitetsstodListEntry2Header: 'epi:aktivitetsstodListEntry2Header',
                aktivitetsstodListEntry2Content: 'epi:aktivitetsstodListEntry2Content',
                aktivitetsstodListEntry2Entry1Header: 'epi:aktivitetsstodListEntry2Entry1Header',
                aktivitetsstodListEntry2Entry2Header: 'epi:aktivitetsstodListEntry2Entry2Header',
                aktivitetsstodListEntry2Entry3Header: 'epi:aktivitetsstodListEntry2Entry3Header',
                aktivitetsstodListEntry2Entry3Content: 'epi:aktivitetsstodListEntry2Entry3Content',
                aktivitetsstodListEntry2Entry3ContentEmail: 'epi:aktivitetsstodListEntry2Entry3ContentEmail',
                aktivitetsstodListEntry3Header: 'epi:aktivitetsstodListEntry3Header',
                aktivitetsstodListEntry3Content: 'epi:aktivitetsstodListEntry3Content',

                studieersattningStudielonHeader: 'epi:studieersattningStudielonHeader',
                skalTillStudielonText: 'epi:skalTillStudielonText',
                angeOrsakText: 'epi:angeOrsakText',
                kortfattadMotiveringText: 'epi:kortfattadMotiveringText',
                skalEjCsnBerattigadText: 'epi:skalEjCsnBerattigadText',
                skalEkonomiskSituationText: 'epi:skalEkonomiskSituationText',
                skalPersonligSituationText: 'epi:skalPersonligSituationText',
                skalAnnatText: 'epi:skalAnnatText',
                beskrivningSlutPaCsn: 'epi:beskrivningSlutPaCsn',
                beskrivningAlder: 'epi:beskrivningAlder',
                beskrivningTidigareSkuld: 'epi:beskrivningTidigareSkuld',
                beskrivningOtillrackligaStudieresultat: 'epi:beskrivningOtillrackligaStudieresultat',
                preliminarErsattningHeader: 'epi:preliminarErsattningHeader',
                beraknadStudiestartText: 'epi:beraknadStudiestartText',
                beraknadUtbetalningstartHeader: 'epi:beraknadUtbetalningstartHeader',
                beraknadUtbetalningsstartArLabel: 'epi:beraknadUtbetalningsstartArLabel',
                varTerminLabel: 'epi:varTerminLabel',
                hostTerminLabel: 'epi:hostTerminLabel',
                taBortKalenderhalvarModalHeader: 'epi:taBortKalenderhalvarModalHeader',
                taBortKalenderhalvarModalDescription: 'epi:taBortKalenderhalvarModalDescription',
                taBortKalenderhalvarModalConfirm: 'epi:taBortKalenderhalvarModalConfirm',
                taBortKalenderhalvarModalCancel: 'epi:taBortKalenderhalvarModalCancel',
                kalenderhalvarTitle: 'epi:kalenderhalvarTitle',
                preliminarStudieersattningText: 'epi:preliminarStudieersattningText',
                kronorPerDagText: 'epi:kronorPerDagText',
                kronorPerFyraVeckorText: 'epi:kronorPerFyraVeckorText',
                preliminarStudielonText: 'epi:preliminarStudielonText',
                laggTillKalenderhalvarText: 'epi:laggTillKalenderhalvarText',
                maxKalenderhalvarText: 'epi:maxKalenderhalvarText',
                aterbetalningsskyldigText: 'epi:aterbetalningsskyldigText',

                varningStartPension: 'epi:varningStartPension',
                varningSlutPension: 'epi:varningSlutPension',
                varningStartFemArsGransen: 'epi:varningStartFemArsGransen',
                varningSlutFemArsGransen: 'epi:varningSlutFemArsGransen',
                varningStartOmstallningsdagarSlutInnanStudiestart: 'epi:varningStartOmstallningsdagarSlutInnanStudiestart',
                varningSlutOmstallningsdagarSlutInnanStudiestart: 'epi:varningSlutOmstallningsdagarSlutInnanStudiestart',
                varningStartSistaDagMedLon: 'epi:varningStartSistaDagMedLon',
                varningSlutSistaDagMedLon: 'epi:varningSlutSistaDagMedLon',
                varningStartAvgangsvederlag: 'epi:varningStartAvgangsvederlag',
                varningSlutAvgangsvederlag: 'epi:varningSlutAvgangsvederlag',

                deleteStodModalCancel: 'epi:deleteStodModalCancel',
                deleteStodModalConfirm: 'epi:deleteStodModalConfirm',
                deleteStodModalHeader: 'epi:deleteStodModalHeader',
                deleteStodModalContentDescription: 'epi:deleteStodModalContentDescription',

                markeratSomKlartInfo: 'epi:markeratSomKlartInfo',

                lamnaIfylltFormularWarningText: 'epi:lamnaIfylltFormularWarningText',

                underlagIsLockedForEditingHeader: 'epi:underlagIsLockedForEditingHeader',
                underlagIsLockedForEditingDescription: 'epi:underlagIsLockedForEditingDescription',
                underlagIsLockedForEditingUnlockButtonText: 'epi:underlagIsLockedForEditingUnlockButtonText',
                underlagIsForbiddenToUnlockHeader: 'epi:underlagIsForbiddenToUnlockHeader',
                underlagIsForbiddenToUnlockDescription: 'epi:underlagIsForbiddenToUnlockDescription',
                underlagUnlockSuccessText: 'epi:underlagUnlockSuccessText',
                underlagUnlockFailureText: 'epi:underlagUnlockFailureText',

                ...defaultKoptUtbildningEpiContent,
                ...defaultKortvarigtStudiestodEpiContent,
                ...defaultKompletterandeStudiestodEpiContent,
            },
            underlagErrorMessages: {
                anledning: 'epi:anledning',
                beraknadUtbetalningsstart: 'epi:beraknadUtbetalningsstart',
                beskrivning: 'epi:beskrivning',
                hasAktivitetstod: 'epi:hasAktivitetstod',
                intervall: 'epi:intervall',
                markeraSomKlar: 'epi:markeraSomKlar',
                motiveringArbetsmarknad: 'epi:motiveringArbetsmarknad',
                studieersattning: 'epi:studieersattning',
                studieersattningTooLong: 'epi:studieersattningTooLong',
                studielon: 'epi:studielon',
                studielonTooLong: 'epi:studielonTooLong',
                studiestodTyp: 'epi:studiestodTyp',
                termin: 'epi:termin',
                tidigareSparatUtkast: 'epi:tidigareSparatUtkast',
                utkast: 'epi:utkast',
                minOneKalenderhalvar: 'epi:minOneKalenderhalvar',
                year: 'epi:year',
                beraknadUtbetalningsstartNotInRange: 'epi:beraknadUtbetalningsstartNotInRange',
                kostnad: 'epi:kostnad',
                kostnadMax: 'epi:kostnadMax',
                kostnadMin: 'epi:kostnadMin',
                heltal: 'epi:heltal',
                ...defaultKortvarigtStudiestodFelMeddelandeEpiContent,
                ...defaultKompletterandeStudiestodFelMeddelandeEpiContent,
            },
        },
        common: defaultStudierTabCommonContent,
    },
}
