/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { mergeAll, omit, isNotNil } from 'ramda'

export interface IEpiBlockProperties extends Record<string, unknown> {
    urlKey: string
    name?: string
    heading?: string
    customDictionary?: Record<string, string>[]
    customLinkDictionary?: Record<string, string>[]
    blockKey?: string
    contentBlockArea?: IEpiBlock[]
}

export interface IEpiBlock extends Record<string, unknown> {
    displayOption: string
    id: number
    properties: IEpiBlockProperties
    type: string
}

export interface IEpiContent extends Record<string, unknown> {
    contentBlockArea?: IEpiBlock[]
}
export const dictionaryToObject = (d: Record<string, string>[]) =>
    mergeAll(
        d.map((o) => ({
            [o.key]: o.value || o.link,
        }))
    )
const blockPropertiesToOmit = ['customDictionary', 'blockKey', 'customLinkDictionary', 'contentBlockArea']

export const filterContentBlockAreaToObject = (block: IEpiBlock): Record<string, unknown> => {
    let nestedProperties = {}
    if (block?.properties?.contentBlockArea) {
        const nestedKeys = block?.properties?.contentBlockArea.map((b) => filterContentBlockAreaToObject(b))
        const nestedContent = nestedKeys.reduce((contentBlock, currentBlock) => ({
            ...contentBlock,
            ...currentBlock,
        }))
        nestedProperties = { ...nestedContent }
    }
    const customDictionaryObject = block?.properties?.customDictionary && {
        ...dictionaryToObject(block?.properties?.customDictionary),
    }
    const customLinkDictionaryObject = block?.properties?.customLinkDictionary && {
        ...dictionaryToObject(block?.properties?.customLinkDictionary),
    }

    return {
        [`${block?.properties?.blockKey}`]: {
            ...customDictionaryObject,
            ...customLinkDictionaryObject,
            ...nestedProperties,
            ...omit(blockPropertiesToOmit, block?.properties),
        },
    }
}

interface C {
    key: string
    contentBlockAreas: IEpiBlock[]
    children: C[]
}

export const mapEpiContent = <T>(content: any): T => {
    const root: C = {
        contentBlockAreas: [],
        key: 'root',
        children: [],
    }
    for (const [key, value] of Object.entries(content)) {
        if (key === 'contentBlockArea') {
            if (Array.isArray(value)) {
                root.contentBlockAreas.push(...value)
            } else {
                console.error(`Value of ${key} should be an array`)
            }
        } else if (isNotNil(value) && (typeof value === 'object' || Array.isArray(value))) {
            const exist = root.contentBlockAreas.find((x) => x.properties?.blockKey === key)
            if (!exist) {
                getAllContentBlockAreas(key, value, root)
            }
        }
    }
    const bar = createObject(root)

    return bar as T
}

export const createObject = (root: C): Record<string, unknown> => {
    let currentRoot = {}
    root.contentBlockAreas.forEach((c) => {
        currentRoot = { ...currentRoot, ...filterContentBlockAreaToObject(c) }
    })
    root.children.forEach((c) => {
        currentRoot = { ...currentRoot, [c.key]: { ...createObject(c) } }
    })

    return currentRoot
}

const getAllContentBlockAreas = (parentKey: string, content: any, blocks: C) => {
    const root: C = {
        contentBlockAreas: [],
        key: parentKey,
        children: [],
    }
    for (const [key, value] of Object.entries(content)) {
        if (key === 'contentBlockArea') {
            if (Array.isArray(value)) {
                root.contentBlockAreas.push(...value)
            } else {
                console.error(`Value of ${key} should be an array`)
            }
        } else if (isNotNil(value) && key !== 'category' && (typeof value === 'object' || Array.isArray(value))) {
            const exist = root.contentBlockAreas.find((x) => x.properties?.blockKey === key)
            if (!exist) {
                getAllContentBlockAreas(key, value, root)
            }
        }
    }
    blocks.children.push(root)
}
