import { useAuthentication, useUser } from '@trr/app-shell-data'

const useCurrentUser = () => {
    const auth = useAuthentication()
    const user = useUser()
    const userHasRole = (rolesToCheck = [] as string[]) => {
        const roles = auth?.userRoles ?? []
        return rolesToCheck.some((role) => roles.includes(role))
    }

    const getUserMdId = () => auth?.sub || ''

    return {
        user,
        userHasRole,
        getUserMdId,
    }
}
export default useCurrentUser
