import { ApiResponseModel } from '@local/services/ApiResponseModel'
import { TrrValidationErrorField } from '@local/services/SwaggerApi/data-contracts'
import { pushFeedback } from '@local/src/components/Toaster/Toaster'
import useCommonTranslation from '@local/src/hooks/useCommonTranslation'
import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'

interface Props {
    apiResponse: ApiResponseModel<unknown>
    successMessage: string
    failureMessage: string
}

const SubmitResponse = ({ apiResponse, successMessage, failureMessage }: Props) => {
    const { tCommon } = useCommonTranslation()

    useEffect(() => {
        if (apiResponse !== undefined) {
            const message = apiResponse.isSuccess ? successMessage : failureMessage
            pushFeedback({
                body: message,
                testSelector: apiResponse.isSuccess ? 'toaster-confirmation' : 'toaster-error',
                isSuccess: apiResponse.isSuccess,
            })
        }
    }, [apiResponse])

    if (!apiResponse) return <></>

    return (
        <Fragment>
            {!apiResponse.isSuccess && (
                <Stack data-testid="api-error-response" spacing={2}>
                    <Typography color="error" variant="h6" data-testid="api-error-response-header">
                        {apiResponse.error?.errorMessage} - {tCommon('error_generic')}
                    </Typography>
                    <List>
                        {apiResponse.error?.validationErrors?.map((err: TrrValidationErrorField, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={err.propertyName} secondary={err.errorMessage} />
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            )}
        </Fragment>
    )
}

export default SubmitResponse
