import { Box, SxProps, TextField, Theme } from '@mui/material'
import { isNotNil } from 'ramda'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type InputType = 'password' | 'number' | 'text'

interface IInputField {
    name: string
    label: string
    inputType?: InputType
    disabled?: boolean
    testId?: string
    wrapperSx?: SxProps<Theme>
    fullWidth?: boolean
}

export const InputField = ({ name, label, inputType, disabled, testId, wrapperSx = {}, fullWidth = true }: IInputField) => {
    const { register, getFieldState, watch } = useFormContext()
    const { error } = getFieldState(name)
    const inputRef = React.useRef<HTMLInputElement>(null)
    const watchValue = watch(name)
    const isValidValue = isNotNil(watchValue)
    useEffect(() => {
        if (inputType !== 'number') return
        if (!inputRef.current) return

        const wheelHandler = (e: WheelEvent) => e.preventDefault()

        inputRef.current.addEventListener('wheel', wheelHandler, { passive: false })

        return () => {
            if (inputRef.current) inputRef.current.removeEventListener('wheel', wheelHandler)
        }
    }, [])

    return (
        <Box sx={wrapperSx}>
            <TextField
                inputRef={inputRef}
                {...register(name, {
                    setValueAs: (val: string) => {
                        if (inputType !== 'number') return val
                        const intVal = parseFloat(val)
                        return isNaN(intVal) ? undefined : intVal
                    },
                })}
                inputProps={{
                    'data-testid': testId || name,
                    step: inputType === 'number' ? 'any' : undefined,
                }}
                InputLabelProps={{ shrink: isValidValue }}
                type={inputType}
                label={label}
                disabled={disabled}
                error={Boolean(error?.message)}
                helperText={error?.message}
                fullWidth={fullWidth}
            />
        </Box>
    )
}
